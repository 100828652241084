import styled from 'styled-components'

export const PageLoaderStyle = styled.div`
  position: absolute;
  top: 22px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
