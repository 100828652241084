import { createGlobalStyle } from 'styled-components'
import 'sanitize.css'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Noto Sans JP', sans-serif;
    /* font-family: 'Red Hat Text', sans-serif; */
    .ant-message {
      white-space: pre;
      .ant-message-custom-content span:not(.anticon) {
        text-align: left;
      }
    }
  }
`
