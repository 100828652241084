
import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { circular } from '../../../utils'
import { SPACING_SIZES } from '../../../constants'

import {
  CardStyle,
  CardInnerStyle
} from './Card.style.js'
import CardHeader from './CardHeader'

const Card = ({
  title,
  toggleName,
  children,
  w,
  h,
  mt,
  ml,
  mb,
  mr,
  mx,
  my
}) => {
  return (
    <CardStyle
      w={w}
      h={h}
      mt={mt}
      ml={ml}
      mb={mb}
      mr={mr}
      mx={mx}
      my={my}
    >
      <CardHeader title={title} toggleName={toggleName}/>
      <CardInnerStyle>
        { children }
      </CardInnerStyle>
    </CardStyle>
  )
}

export default memo(Card, (prevProps, nextProps) => {
  const prev = JSON.stringify(prevProps, circular())
  const next = JSON.stringify(nextProps, circular())
  return prev === next
})

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  toggleName: PropTypes.string,
  w: PropTypes.number,
  h: PropTypes.number,
  mt: PropTypes.oneOf(SPACING_SIZES),
  ml: PropTypes.oneOf(SPACING_SIZES),
  mb: PropTypes.oneOf(SPACING_SIZES),
  mr: PropTypes.oneOf(SPACING_SIZES),
  mx: PropTypes.oneOf(SPACING_SIZES),
  my: PropTypes.oneOf(SPACING_SIZES)
}
Card.defaultProps = {

}
