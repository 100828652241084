import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { useNavigate } from '@reach/router'
import { message } from 'antd'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import configureAxios from '../../utils/configureAxios'

import PageLoader from '../../components/Layout/PageLoader'
import Centered from '../../components/Layout/Centered/Centered'
import Card from '../../components/Layout/Card'
import { Column, Row } from '../../components/Layout/Block'
import { Text } from '../../components/Display/Typography/Typography'

import { ErrorMessageStyle, FormStyle, InputContainerStyle, PrimaryButtonStyle, TextInput, TitleStyle } from './UserResetPage.style'
import { useTranslation } from 'react-i18next'
import { userResetSchema } from '../../validation'
import { SvgContainerStyle } from '../UserVerifySuccessPage/UserVerifySuccessPage.style'

const {
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG,
  REACT_APP_APPLI_API_AUHTORIZATION_KEY
} = process.env

const api = configureAxios(process.env.REACT_APP_APPLI_API_URL)

function UserResetPage ({ location }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setIsLoading] = useState(true)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      password: '',
      password_confirm: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(userResetSchema())
  })
  const [success, setSuccess] = useState(false)

  const verifyToken = useCallback(
    async (token) => {
      if (token) { // token found, verify it
        try {
          await api.post('/password/verify', { token }, {
            headers: {
              Authorization: REACT_APP_APPLI_API_AUHTORIZATION_KEY
            }
          })
        } catch (error) { // error during verify, redirect to 404
          // message.error('認証エラー')
          navigate('/not_found', { replace: true })
        }
      } else { // no token found, redirect to 404
        // message.error('パラメーターエラー')
        navigate('/not_found', { replace: true })
      }
      setIsLoading(false)
    },
    [navigate]
  )

  useEffect(() => {
    const query = parse(location && location.search)
    const { token } = query
    verifyToken(token)
  }, [location, verifyToken])

  const _handleSubmit = async (data) => {
    const query = parse(location && location.search)

    try {
      await api.post('/password/reset', {
        token: query?.token,
        password: data.password
      }, {
        headers: {
          Authorization: REACT_APP_APPLI_API_AUHTORIZATION_KEY
        }
      })
      setSuccess(true)
    } catch (error) {
      message.error('登録エラー')
      console.error(error)
    }
  }

  if (loading) {
    return <PageLoader />
  }

  if (success) {
    return (
      <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
        <Card w={460} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
          <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
            <Column px={'L'} pt={'M'} pb={'L'}>
              <TitleStyle label="user_reset:page_title_success"/>
              <SvgContainerStyle>
                <svg version="1.1" id="_x32_" x="0px" y="0px" style={{ width: '40px' }} viewBox="0 0 512 512" xmlSpace="preserve">
                  <polygon className="st0" points="440.469,73.413 218.357,295.525 71.531,148.709 0,220.229 146.826,367.055 218.357,438.587 289.878,367.055 512,144.945 " style={{ fill: '#f4a800' }}></polygon>
                </svg>
              </SvgContainerStyle>
              <Row pt="L" justify="center">
                <Text label="user_reset:success" />
              </Row>
            </Column>
          </Centered>
        </Card>
      </Centered>
    )
  }

  return (
    <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
      <Card w={460} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
        <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
          <Column px={'L'} pt={'M'} pb={'L'}>
            <TitleStyle label="user_reset:page_title"/>
            <Row pt="L">
              <Text label="user_reset:reset_condition" />
            </Row>
            <Row mt="L">
              <FormStyle
                id="reset_password"
                onSubmit={handleSubmit(_handleSubmit)}
                noValidate
              >
                <InputContainerStyle>
                  <TextInput
                    type='password'
                    name='password'
                    placeholder={t('user_reset:password')}
                    required
                    ref={register({ required: true })}
                  />
                  {errors.password && <ErrorMessageStyle>{errors?.password?.message}</ErrorMessageStyle>}
                </InputContainerStyle>
                <InputContainerStyle>
                  <TextInput
                    type='password'
                    name='password_confirm'
                    placeholder={t('user_reset:password_confirm')}
                    required
                    ref={register({ required: true })}
                  />
                  {errors.password_confirm && <ErrorMessageStyle>{errors?.password_confirm?.message}</ErrorMessageStyle>}
                </InputContainerStyle>

              </FormStyle>
            </Row>
            <Row pt={'L'}>
              <PrimaryButtonStyle
                reverse
                label="user_reset:submit_password"
                loading={loading}
                form="reset_password"
              />
            </Row>

          </Column>
        </Centered>
      </Card>
    </Centered>
  )
}

UserResetPage.propTypes = {
  location: PropTypes.object
}

export default UserResetPage
