import styled from 'styled-components'

export const AgreementContainer = styled.div`
  margin: 20px;
  line-height: 150%;
  ol {
    padding-left: 1.5rem;
  }
  p {
    margin-bottom: 20px;
  }
`
