/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import i18n from './_i18n'

export const userResetSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    password: yup
      .string()
      .isLetterOrNumber()
      .min(8)
      .max(20)
      .required()
      .label(i18n.t('user_reset:password')),
    password_confirm: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n.t('validation:check_password', { label: '${label}' }))
      .isLetterOrNumber()
      .min(8)
      .max(20)
      .required()
      .label(i18n.t('user_reset:password_confirm'))
  })
}
