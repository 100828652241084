import React from 'react'
// import PropTypes from 'prop-types'

import LOGO from '../../assets/img/oikura_logo.png'

import {
  ContainerStyle,
  InnerContainerStyle,
  TitleStyle,
  LogoStyle,
  DescriptionStyle,
  AboutStyle,
  StepsStyle,
  ButtonStyle
} from './OikuraInfo.style'

const buttonImage = 'https://creative.prf.hn/source/screativeref:1100l94780'
const buttonLink = 'https://prf.hn/click/camref:1100lfBo8/creativeref:1011l51030/screativeref:1100l94780'

function OikuraInfo () {
  return (
    <ContainerStyle>
      <InnerContainerStyle>
        <TitleStyle>「不用品買取の一括査定サービス」</TitleStyle>
        <LogoStyle className="logo">
          <img src={LOGO} alt=""/>
        </LogoStyle>

        <DescriptionStyle>
          処分にお金がかかってしまう...
          <br />
          大型品なので自分では対処できない...
          <br />
          どの店が一番良いのか分からない...
          <br />
          そんなお悩みを「おいくら」で解決！
        </DescriptionStyle>

        <AboutStyle>
        「おいくら」では...
          <ul>
            <li>・処分せずに売ってお金に！</li>
            <li>・出張買取でご自宅内にて完結！</li>
            <li>・一度に最大20社の査定結果を比較！</li>
          </ul>
        </AboutStyle>

        <StepsStyle>
        【ご依頼は簡単3ステップ！】
          <ul>
            <li>①売却するお品物情報を入力</li>
            <li>②お客様情報と買取方法を入力して依頼完了</li>
            <li>③査定結果が届いたら条件に合うお店に買取依頼</li>
          </ul>
        </StepsStyle>

        <ButtonStyle href={buttonLink} target="_blank" rel="noopener noreferrer">
          <img src={buttonImage} alt=""/>
        </ButtonStyle>

      </InnerContainerStyle>
    </ContainerStyle>
  )
}

// OikuraInfo.propTypes = {

// }

export default OikuraInfo
