import React from 'react'
import ReactDOM from 'react-dom'
// import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
// import errorMonitors from './utils/errorMonitors'
// import configureStore from './store'
import App from './App'
// import { initGA } from './utils/analytics'

import './locales/i18n'

// errorMonitors()
// initGA()

// const store = configureStore()

if (process.env.NODE_ENV === 'development') {
  require('./utils/console')
  /* eslint-disable-next-line no-unused-vars */
  const whyDidYouRerender = () => {
    const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
    const ReactRedux = require('react-redux')
    whyDidYouRender(React, {
      onlyLogs: true,
      titleColor: '#fa983a',
      diffNameColor: '#78e08f',
      trackAllPureComponents: true,
      trackExtraHooks: [
        [ReactRedux, 'useSelector']
      ],
      exclude: [
        /^Routes/, // rerender because of hook change (probably because of i18next)
        /^NavBar/, // rerender because of hook change (probably because of i18next)
        /^RouterImpl/, // rerender because of children props change (probably because ???)
        /^Form/, // rerender because of children props change (probably because ???)
        /^Modal/, // rerender because of hook change (probably because t
        /^DatePicker/, // rerender because of props change (probably because of antd)
        /^Main/, // rerender because of props change (probably because of ???)
        /^PageLayout/, // rerender because of props change (probably because of refetch of same page)
        /^ColumnComponent/, // rerender because of children props change (probably because ???)
        /^RowComponent/, // rerender because of children props change (probably because ???)
        /^Centered/, // rerender because of children props change (probably because ???)
        /^Card/ // rerender because of children props change (probably because ???)
      ]
    })
  }
  whyDidYouRerender()
}

ReactDOM.render(
  <App />,
  // <Provider store={store}>
  // </Provider>,
  document.getElementById('root'))

// expose store when run in Cypress
// if (window.Cypress) {
//   window.store = store
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
