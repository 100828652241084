import styled from 'styled-components'
import {
  getColor,
  SPACING
} from '../../constants'

export const ContainerStyle = styled.div`
  padding: 4px;
  width: 100%;
  height: 100vh;
`
export const InnerContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: ${props => getColor(props, 'accent')};
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-right-width: 8px;
  border-left-width: 8px;
  padding: ${SPACING.REGULAR}rem ${SPACING.SMALL}rem;
  box-sizing: border-box;
  font-size: clamp(0.8rem, 3.7vw, 1rem);
  overflow-y: scroll;
`

export const TitleStyle = styled.h1`
  margin: ${SPACING.SMALL}rem auto ${SPACING.SMALLEST}rem auto;
  font-size: clamp(12px, 4vw, 17px);
  text-align: center;
`
export const LogoStyle = styled.div`
  width: 75%;
  margin: auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`
export const DescriptionStyle = styled.p`
  margin: ${SPACING.MEDIUM_LARGE}rem 0 ${SPACING.MEDIUM}rem 0;

`
export const AboutStyle = styled.div`
  ul {
    list-style: none;
    margin: ${SPACING.SMALL}rem 0 0 0;
    padding: 0 0 0 ${SPACING.SMALL}rem;
    font-weight: bold;
    font-size: clamp(0.8rem,4vw,1rem);
  }
`
export const StepsStyle = styled.div`
  margin-top: ${SPACING.REGULAR}rem;
  ul {
    list-style: none;
    margin: ${SPACING.SMALL}rem 0 0 0;
    padding: 0 0 0 ${SPACING.SMALL}rem;
    font-weight: bold;
  }
`

export const ButtonStyle = styled.a`
  display: block;
  width: 85%;
  margin: ${SPACING.MEDIUM_LARGE}rem auto 0 auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`
