// import moment from 'moment'
export const GOOGLE_TRACKING_ID = 'UA-142101918-8'

export const EMAIL = 'info@appme-cloud.com'

export const SERVER_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const iOS_DEEP_LINK_PROTOCOL = 'app-me-cloud://'
export const ANDROID_DEEP_LINK_PROTOCOL = 'appmecloud://'

export const APPLI_UA = ''

export const INUI_CONTACT_TYPE = {
  BICYCLE_PARKING: 1,
  PARKING: 2,
  BIKE_PARKING: 3,
  TRUNK_ROOM: 4,
  PREMIUM_BICYCLE_PARKING: 5
}
export const INUI_PROPERTIES = {
  PLAZA_TOWER_KACHIDOKI: 1,
  PLAZA_KACHIDOKI: 2,
  TSUKISHIMA: 3
}

export const APP_IDS = {
  INUI: '11',
  CONSHELLY: '14',
  YASUE: '19'
}
