import React from 'react'
import PropTypes from 'prop-types'

import { INUI_PROPERTIES } from '../../constants'

import {
  TableTitleStyle,
  TableContainerStyle,
  ListFlexStyle,
  ListDivStyle,
  TableTdTitleStyle,
  TableTdtextStyle,
  ListContentStyle
} from './InuiContact.style'

function Tables ({ property }) {
  const _rendertable = () => {
    switch (property) {
      case INUI_PROPERTIES.PLAZA_TOWER_KACHIDOKI:
        return (
          <div>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>タワーパーキング<br/>普通駐車場</TableTdTitleStyle>
                  <TableTdtextStyle>全長：5,000(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車幅：1,900(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車高：1,550(mm)</TableTdtextStyle>
                  <TableTdtextStyle>重量：2,000(kg)</TableTdtextStyle>
                  <TableTdtextStyle>22,000円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>タワーパーキング<br/>ハイルーフ</TableTdTitleStyle>
                  <TableTdtextStyle>全長：5,000(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車幅：1,900(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車高：2,050(mm)</TableTdtextStyle>
                  <TableTdtextStyle>重量：2,000(kg)</TableTdtextStyle>
                  <TableTdtextStyle>27,500円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>二段式駐車場<br/>（下段）</TableTdTitleStyle>
                  <TableTdtextStyle>全長：5,300(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車幅：1,950(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車高：1,550(mm)</TableTdtextStyle>
                  <TableTdtextStyle>重量：2,300(kg)</TableTdtextStyle>
                  <TableTdtextStyle>38,500円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>二段式駐車場<br/>（上段）</TableTdTitleStyle>
                  <TableTdtextStyle>全長：5,300(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車幅：1,950(mm)</TableTdtextStyle>
                  <TableTdtextStyle>車高：2,000(mm)</TableTdtextStyle>
                  <TableTdtextStyle>重量：2,300(kg)</TableTdtextStyle>
                  <TableTdtextStyle>44,000円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>平置</TableTdTitleStyle>
                  <TableTdtextStyle>全長：-</TableTdtextStyle>
                  <TableTdtextStyle>車幅：-</TableTdtextStyle>
                  <TableTdtextStyle>車高：-</TableTdtextStyle>
                  <TableTdtextStyle>重量：-</TableTdtextStyle>
                  <TableTdtextStyle>49,500円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>原付（50cc）</TableTdTitleStyle>
                  <TableTdtextStyle>5,500円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>バイク（125cc超）</TableTdTitleStyle>
                  <TableTdtextStyle>8,800円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>トランクルーム<br/>大</TableTdTitleStyle>
                  <TableTdtextStyle>4,400円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>トランクルーム<br/>小</TableTdTitleStyle>
                  <TableTdtextStyle>3,850円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>駐輪場</TableTdTitleStyle>
                  <TableTdtextStyle>550円/月（税込）<br/>年払い</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
          </div>
        )
      case INUI_PROPERTIES.PLAZA_KACHIDOKI:
        return (
          <div>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>平置</TableTdTitleStyle>
                  <TableTdtextStyle>全長：4,350-5,000（mm）</TableTdtextStyle>
                  <TableTdtextStyle>車幅：2,300-2,800（mm）</TableTdtextStyle>
                  <TableTdtextStyle>車高：3,250-3,800（mm）</TableTdtextStyle>
                  <TableTdtextStyle>27,500円/月（税込）</TableTdtextStyle>
                  <TableTdtextStyle>保証金：1ヶ月</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>原付（50cc）</TableTdTitleStyle>
                  <TableTdtextStyle>1,540円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>バイク（125cc超）</TableTdTitleStyle>
                  <TableTdtextStyle>5,940円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>トランクルーム<br/>普通</TableTdTitleStyle>
                  <TableTdtextStyle>3,740円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>トランクルーム<br/>小</TableTdTitleStyle>
                  <TableTdtextStyle>3,080円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>駐輪場</TableTdTitleStyle>
                  <TableTdtextStyle>550円/月（税込）<br/>年払い</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
          </div>
        )
      case INUI_PROPERTIES.TSUKISHIMA:
        return (
          <div>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>バイク置き場</TableTdTitleStyle>
                  <TableTdtextStyle>奥行き：2,300（mm）</TableTdtextStyle>
                  <TableTdtextStyle>幅：1,000（mm）</TableTdtextStyle>
                  <TableTdtextStyle>5,500円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>トランクルーム<br/>大</TableTdTitleStyle>
                  <TableTdtextStyle>3,850円/月（税込）</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
            <ListFlexStyle>
              <ListDivStyle>
                <ListContentStyle>
                  <TableTdTitleStyle>駐輪場</TableTdTitleStyle>
                  <TableTdtextStyle>3,300円（税込）<br/>年払い</TableTdtextStyle>
                </ListContentStyle>
              </ListDivStyle>
            </ListFlexStyle>
          </div>
        )
      default:
        return null
    }
  }
  return (
    <TableContainerStyle>
      <TableTitleStyle>サイズ制限、料金情報</TableTitleStyle>
      <div>
        {_rendertable()}
      </div>
    </TableContainerStyle>
  )
}

Tables.propTypes = {
  property: PropTypes.number.isRequired
}

export default Tables
