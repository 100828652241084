import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import ErrorInfo from './ErrorInfo'

class ErrorBoundary extends Component {
    state = {
      error: null,
      errorInfo: null,
      eventId: null
    }

    componentDidCatch (error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      // You can also log error messages to an error reporting service here
      // TODO: set user id and email from Local Storage and App version too
      Sentry.withScope(scope => {
        scope.setUser({
          id: 'customID123ABC',
          email: 'test@test.fr',
          username: 'Test User'
        })
        scope.setTag('version', '1.9.0')
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId, error, errorInfo })
      })
    }

    render () {
      if (this.state.errorInfo) {
        const { error, errorInfo, eventId } = this.state
        return <ErrorInfo eventid={eventId} error={error} errorInfo={errorInfo} />
      }
      // Normally, just render children
      return this.props.children
    }
}

export default ErrorBoundary

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}
