import * as yup from 'yup'
import i18n from './_i18n'
import dayjs from 'dayjs'
import { isObject } from 'lodash'

/* eslint-disable no-template-curly-in-string */

// NOTE: Do not use arrow function here, need to keep "this" scope
const parseDayjsDate = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Date Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const isDate = isObject(value) && dayjs(value).isValid()
      return isDate || createError({ path, message })
    }
    return true
  })
}
const isLessTest = function (comparator, comparatorLabel) {
  const message = i18n.t('min_error', { label: '${label}', comparator: comparatorLabel })
  return this.test('Limit Error', message, function (value) {
    const { path, createError } = this
    const maxValue = this.parent[comparator]
    // console.debug('isLess')
    // console.debug(` ${value} > ${maxValue} => ${value > maxValue}`)
    if (!maxValue || !value) {
      return true
    }
    return value > maxValue || createError({ path, message })
  })
}
const isMoreTest = function (comparator, comparatorLabel) {
  const message = i18n.t('max_error', { label: '${label}', comparator: comparatorLabel })
  return this.test('Limit Error', message, function (value) {
    const { path, createError } = this
    const minValue = this.parent[comparator]
    // console.debug('isMore')
    // console.debug(` ${value} < ${minValue} => ${value < minValue}`)
    if (!minValue || !value) {
      return true
    }
    return value < minValue || createError({ path, message })
  })
}

const isLetterOrNumberTest = function () {
  const message = i18n.t('letter_or_number', { label: '${label}' })
  return this.test('半角英数字 Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^[a-zA-Z0-9]*$/
      const isLetterorNumber = regex.test(value)
      // console.debug('isLetterorNumber', isLetterorNumber, path, message)
      return isLetterorNumber || createError({ path, message })
    }
    return true
  })
}

const customizeYup = () => {
  // Add isDate method to the object validation object
  yup.addMethod(yup.object, 'isDate', parseDayjsDate)
  yup.addMethod(yup.number, 'isLess', isLessTest)
  yup.addMethod(yup.number, 'isMore', isMoreTest)
  yup.addMethod(yup.string, 'isLetterOrNumber', isLetterOrNumberTest)

  yup.setLocale({
    mixed: {
      required: i18n.t('required', { label: '${label}' }),
      notType: i18n.t('not_type', { label: '${label}' })
      // notType: function notType(_ref) {
      //   switch (_ref.type) {
      //     case 'number':
      //       return 'Not a number error or any other custom error message';
      //     case 'string':
      //       return 'Not a string error or any other custom error message';
      //     default:
      //       return 'Wrong type error or any other custom error message';
      //   }
      // }
    },
    string: {
      max: i18n.t('max_length', { label: '${label}' }),
      min: i18n.t('wrong_length', { label: '${label}' }),
      length: i18n.t('wrong_length', { label: '${label}' }),
      matches: i18n.t('no_match', { label: '${label}' }),
      email: i18n.t('not_email', { label: '${label}' })
      // min: 'Valor muito curto (mínimo ${min} caracteres)',
      // max: 'Valor muito longo (máximo ${max} caracteres)'
    },
    number: {
      integer: i18n.t('not_integer')
      // min: 'Valor inválido (deve ser maior ou igual a ${min})',
      // max: 'Valor inválido (deve ser menor ou igual a ${max})'
    },
    boolean: {
    }
  })
  return yup
}

export default customizeYup
