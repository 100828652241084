import React from 'react'

export function Inui () {
  return (
    <>
      <p>
        本サービスは、乾汽船株式会社 (以下「当社」といいます。）が定めるこの利用規約(以下「本規約」といいます。）に従い提供されます。
        <br/>
        お客様は本サービスを、本規約に承諾の上利用するものとします。お客様が本サービスの利用を開始した場合は、本規約を承諾したものとみなされます。
      </p>
      <h4>第1条 本サービスの概要</h4>
      <ol>
        <li>本サービスにおいて、お客様は、物件情報閲覧サービス、情報配信サービスその他当社が定めるサービスを利用することができます。</li>
        <li>本サービスは、情報料を必要としない無料サービスです。</li>
      </ol>
      <h4>第2条 サービス契約の成立</h4>
      <p>本サービスを利用する場合、お客様は、当社の指定する情報を登録いただく必要があります。当該登録手続きの完了時に、お客様と当社の間に本サービスに関する契約(以下「サービス契約」といいます。）が成立します。</p>
      <h4>第3条 禁止事項</h4>
      <p>お客様は本サービス、当社所定のID及びパスワード(以下「ID等」といい、お客様が本サービスの一部の機能を利用する際に取得が必要となる場合があります。）、及び本サービスで使用されているソフトウェア、ドキュメント、データ、画像、キャラクター、ロゴ等につき、次に定めることを行ってはなりません。</p>
      <ol>
        <li>本サービスを手段として直接に金銭その他商業利益を求めること</li>
        <li>製品表示、著作権表示、その他注意文言、又は財産権に基づく制限事項を削除又は変更すること</li>
        <li>複製、頒布、公衆送信、改変、翻訳、翻案又は二次的著作物を作成すること</li>
        <li>第三者に移転、販売、譲渡、名義変更その他の処分をすること</li>
        <li>再使用許諾、貸与その他方法の如何を問わず、第三者に使用させること</li>
        <li>リバースエンジニアリングその他解析行為をすること</li>
        <li>本サービスの運営を妨害し若しくは本サービスの信用を毀損し又はそれらのおそれがある行為をすること</li>
        <li>お客様又は第三者の個人情報を書き込むこと</li>
        <li>法令に違反する、又は違反する可能性がある行為をすること</li>
        <li>当社しくは第三者の権利を侵害、制限、妨害し又はそのおそれがある行為をすること</li>
        <li>前各号の他、当社が不適切と判断する行為をすること</li>
      </ol>
      <h4>第4条 利用上の注意・警告</h4>
      <ol>
        <li>本サービスは、お客様が使用する端末情報(以下「本情報端末」といいます。)の電波の受信状況その他本情報端末の機能上の制限又はお客様による本情報端末の設定により、提供を受けられないことがあります。</li>
        <li>本サービスにおける物件、メニュー、地図、施設・店舗、イベント等に関する情報(以下併せて「本サービス情報」といいます。）は、実際の情報と異なる場合（存在しない場合も含みます。）や内容等が正確でない場合（内容等が変更されている場合も含みます。）があります。また、本サービス情報は、お客様への通知なしに変更されることがあります。</li>
        <li>本サービス情報には、当社から投稿される情報、画像等、(以下、併せて「書き込み情報」といいます。）が含まれます。書き込み情報については、第6条の内容を確認した上で、あくまで参考情報として利用してください。</li>
        <li>本サービスが対象としている国又は地域のうち、本サービス情報の提供を受けられない場所が一部あります。</li>
      </ol>
      <h4>第5条 書き込み情報</h4>
      <ol>
        <li>本サービスの書き込み情報には不正確又は不適切な内容が含まれる場合がありますが、当社は、書き込み情報の内容の正確性・完全性・有用性を保証せず、不正確又は不適切な内容の書き込み情報を完全に排除するものではありません。</li>
        <li>書き込み情報は、投稿した当社からの主観的な情報であると同時に、時間の経過によって変化したりします。当該性質をよく理解した上で、お客様ご自身の責任で参考にするか否かの判断をしてください。</li>
      </ol>
      <h4>第6条 本サービス利用に関する費用</h4>
      <p>本サービスは第1条第2項に定めるとおり情報料を必要としない無料サービスですが、その利用には、電気通信事業者の定めるところに従い、別途通信量等が発生する場合があります。通信量等は、お客様が負担するものとします。</p>
      <h4>第7条 本サービスの中断</h4>
      <p>当社は、次の各号の何れかに該当する事由が生じた場合には、お客様に事前に通知することなく、本サービスの一部又は全部の提供を中断する場合があります。</p>
      <ol>
        <li>本サービスを提供するためのシステムの保守点検を定期的又は緊急に行う場合</li>
        <li>天災地変、火災、停電、騒乱等により本サービスの提供が難しい場合</li>
        <li>電気通信回線、コンピュータ等の通信手段の障害などにより本サービスの提供が難しい場合</li>
        <li>前各号の他、運用上又は技術上の理由で本サービスの提供が難しい場合</li>
      </ol>
      <h4>第8条 サービス契約の解約</h4>
      <ol>
        <li>お客様がサービス契約を解約する場合、当社の指定する方法により解約手続きをする必要があります。</li>
        <li>お客様が本サービスを利用する端末情報、利用方法によっては、本情報端末について電気通信事業者との通信サービス契約を終了された場合でも、サービス契約が自動的に終了しない場合があります。通信サービスの契約を終了する前に、サービスの解約手続きを行ってください。</li>
      </ol>
      <h4>第9条 本サービスの変更、終了</h4>
      <ol>
        <li>当社は、当社の裁量において、お客様に事前に通知することなく、本サービスの内容を変更又は提供の終了をすることができるものとします。</li>
        <li>当社は、前項の内容変更又は提供終了に関連してお客様に生じた損害につき、一切責任を負いません。</li>
      </ol>
      <h4>第10条 知的財産権</h4>
      <p>本サービスに関わる知的財産権その他の一切の権利は、当社又は当社に対して当該知的財産権等の利用を許諾する第三者に帰属します。</p>
      <h4>第11条 個人情報及びその他情報の取扱い</h4>
      <ol>
        <li>当社は、本サービスの提供を通じて当社が取得するお客様の個人情報について、当社所定の「個人情報保護方針」(http://www.inui.co.jp/privacy/)を遵守し、適切に管理するものとします。</li>
        <li>当社はお客様による本サービスの利用によって取得する情報（来店履歴等のデータ、GPS機能により取得される位置情報、移動経路情報等を含みますが、これらに限られません。）について、本サービス若しくはこれに関連するサービス又は当社のその他の事業のために利用(統計資料の作成、及び当該事業に必要な範囲において第三者に提供することを含みます。）することがあります。</li>
        <li>前項の情報を第三者に提供する場合、適切な匿名化処置を施し、匿名化した情報を再識別化しないものとします。また。これらの情報を第三者に提供する場合には、提供先が再識別化をすることを契約で禁止します。第1項に定める「個人情報の取扱い」の規定によらずに、お客様の個人情報を第三者に提供することはありません。</li>
        <li>当社は、お客様の個人情報を基に又は第2項の情報を基に作成した統計資料については、何ら制限なしに取り扱うものとします。</li>
      </ol>
      <h4>第12条 保証及び責任</h4>
      <ol>
        <li>当社は、本サービスの利用又は利用不能に関連して生じる損害に関して、本規約に明示のあるものを除き、一切の責任を負いません。</li>
        <li>当社は、本サービスの正確性、完全性、安全性、本サービスがお客様の特定の目的に適合し又は有効であること等につき、何ら保証するものではなく、また、本サービスに瑕疵のないこと、本サービスが不具合なく動作すること等についても、何ら保証するものではありません。</li>
        <li>お客様の管理不十分その他当社の責に帰すべき事由によらず生じたID等に関する損害については、お客様が責任を負うものとし、当社は一切の責任を負いません。</li>
        <li>第7条各号に掲げる事由に起因する本サービスの全部又は一部の提供の中断によりお客様に発生した損害につき、当社は一切の責任を負いません。</li>
        <li>本規約に基づくお客様と当社の契約が消費者契約法(平成12年法律第61号)第2条第3項の消費者契約に該当する場合には、本契約のうち、当社の責任を完全に免責する規定は適用されないものとします。この場合においてはお客様に生じた損害が当社の債務不履行又は不法行為に基づくときには、当社に故意又は重大な過失がある場合を除き、当社は、お客様が現実に被った直接かつ通常の損害についてのみ賠償することとし、その賠償額は月額賃料1ヶ月分を限度額とします。</li>
      </ol>
      <h4>第13条 一般条項</h4>
      <ol>
        <li>お客様は、当社の書面による事前承諾がない限り、本サービスの利用に係る契約上の地位を第三者に承諾させ、又はその権利義務の全部若しくは一部を第三者に譲渡し、担保に供し若しくは引受させてはなりません。</li>
        <li>お客様が本規約の一に違反した場合、当社は、何らの催告を要せず直ちに本サービスのお客様に対する提供を停止し、サービス契約を解除することが出来ます。</li>
        <li>当社は、予め本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を周知することにより、お客様の承諾なく、本規約を変更できるものとします。当該周知は、本サービス上に公開することによって行うものとします。</li>
        <li>本規約のいずれかの条項又はその一部が消費者契約法その他法令等により、無効又は執行不能を判断された場合であっても、当該条項は当該一部以外の本規約の条項の効力には何ら影響を与えないものとします。</li>
        <li>本規約は、日本国の法律に基づき解釈されます。</li>
        <li>本サービスに関し訴訟の必要が生じた場合は、東京地方裁判所をもって第一審の専属管轄裁判所とします。</li>
      </ol>
    </>
  )
}
