
import React, { useState } from 'react'
import queryString from 'query-string'
import { message } from 'antd'
// import PropTypes from 'prop-types'
import {
  useLocation,
  Redirect
} from '@reach/router'

import configureAxios from '../../utils/configureAxios'

import Centered from '../../components/Layout/Centered'
import Card from '../../components/Layout/Card'
import { Column, Row } from '../../components/Layout/Block'

import {
  Text
} from '../../components/Display/Typography'

import {
  TitleStyle,
  PrimaryButtonStyle,
  DangerButtonStyle,
  ImageStyle
} from './ApprovalPage.style.js'

const {
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG
} = process.env

const api = configureAxios()

const ApprovalPage = () => {
  const location = useLocation()
  const queries = queryString.parse(location.search)
  const [loading, setIsLoading] = useState(false)
  const [refusal, setRefusal] = useState(false)
  const [success, setSuccess] = useState(false)

  const _handleClick = async (token) => {
    setIsLoading(true)
    try {
      const res = await api.post('app_users/approval', { token })
      setIsLoading(false)
      if (res && res.status === 204) {
        message.success('success')
        setSuccess(true)
      } else {
        message.error('サーバエラー')
      }
    } catch (error) {
      console.warning('error:', error.data)
      setIsLoading(false)
      message.error(error.data.message)
    }
  }

  const _handelcancel = () => {
    setRefusal(true)
  }

  const _renderImage = () => {
    if (queries.image) {
      return (
        <Row pt={'M'} pb={'L'} justify={'center'}>
          <ImageStyle src={queries.image} w={'120px'} h={'120px'} contain alt={queries.name} />
        </Row>
      )
    } else {
      return (
        <Row pt={'M'}>

        </Row>
      )
    }
  }

  if (queries.name && queries.token) {
    const { name, token } = queries
    return (
      <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
        <Card w={320} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
          <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
            {!refusal && !success ? (
              <Column px={'L'} pt={'M'} pb={'L'}>
                <TitleStyle label={'個人情報利用承認'}/>
                {_renderImage()}
                <Row>
                  <Text label={`${decodeURI(name)}があなたの情報の使用を要請しています。`} />
                </Row>
                <Row pt={'L'}>
                  <PrimaryButtonStyle reverse label={'許可'} loading={loading} handleClick={() => _handleClick(token)}/>
                </Row>
                <Row pt={'M'}>
                  <DangerButtonStyle label={'拒否'} disabled={loading} handleClick={() => _handelcancel()}/>
                </Row>
              </Column>
            ) : success ? (
              <Column px={'L'} pt={'M'} pb={'L'}>
                <TitleStyle label={'個人情報利用承認'} />
                {_renderImage()}
                <Row pt={'M'} justify={'center'}>
                  <Text label={'承認されました。'} />
                </Row>
                <Row justify={'center'}>
                  <Text label={'ブラウザを閉じてください。'} />
                </Row>
              </Column>
            ) : (
              <Column px={'L'} pt={'M'} pb={'L'}>
                <TitleStyle label={'個人情報利用承認'} />
                {_renderImage()}
                <Row pt={'M'} justify={'center'}>
                  <Text label={'拒否されました。'} />
                </Row>
                <Row justify={'center'}>
                  <Text label={'ブラウザを閉じてください。'} />
                </Row>
              </Column>
            )}
          </Centered>
        </Card>
      </Centered>
    )
  }

  return (
    <Redirect to="/not_found" noThrow />
  )
}
export default ApprovalPage

ApprovalPage.propTypes = {

}
ApprovalPage.defaultProps = {

}
