import React from 'react'
import { NOT_FOUND_IMG } from '../../constants/images'

import Centered from '../../components/Layout/Centered'
import { Row, Column } from '../../components/Layout/Block'
import Image from '../../components/Display/Image'
import {
  CustomText,
  CardTitle
} from '../../components/Display/Typography/Typography'

const NotFoundPage = () => {
  return (
    <Centered fullScreen>
      <Column>
        <Row mb={'R'} justify={'center'}>
          <Image src={NOT_FOUND_IMG} w={'200px'} h={'auto'} />
        </Row>
        <CustomText label={'not_found:not_found'} textColor={'#F4A800'} textSize={'2rem'}/>
        <Row mt={'R'}>
          <Row wrap={'wrap'}>
            <Row justify={'center'}>
              <CardTitle label={'not_found:wrong_url'}/>
            </Row>
            <Row justify={'center'}>
              <CardTitle label={'not_found:follow_link'}/>
            </Row>
          </Row>
        </Row>
      </Column>
    </Centered>
  )
}
export default NotFoundPage

NotFoundPage.propTypes = {

}
NotFoundPage.defaultProps = {

}
