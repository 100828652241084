import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import { ErrorMessageStyle, InputContainerStyle, DatePickerStyle } from './InuiContact.style'

function DatePicker ({ name, placeholder }) {
  const { register, errors } = useFormContext()

  return (
    <InputContainerStyle>
      <DatePickerStyle
        type='date'
        name={name}
        ref={register({ required: true })}
        placeholder={placeholder}
        required
      />
      {errors[name] && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
    </InputContainerStyle>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default DatePicker
