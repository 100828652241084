import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { CardTitle } from '../../Display/Typography'

import { CardTitleStyle } from './Card.style'

const CardHeader = ({ title, toggleName }) => {
  if (!title) return null

  return (
    <CardTitleStyle justify="between">
      <CardTitle label={title} />
    </CardTitleStyle>
  )
}

export default memo(CardHeader)

CardHeader.propTypes = {
  title: PropTypes.string,
  toggleName: PropTypes.string
}
