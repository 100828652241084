import React from 'react'
import PropTypes from 'prop-types'
import {
  SpinnerStyle
} from './Spinner.style.js'
import { useTranslation } from 'react-i18next'

const Spinner = ({ size, color, description, delay, className }) => {
  const { t } = useTranslation()
  return (
    <SpinnerStyle
      size={size}
      tip={t(description)}
      color={color}
      delay={delay}
      className={className}
    />
  )
}
export default Spinner

Spinner.propTypes = {
  color: PropTypes.oneOf(['white', 'accent', 'info', 'success', 'danger', 'warning', 'text']),
  size: PropTypes.oneOf(['small', 'default', 'large']),
  description: PropTypes.string,
  delay: PropTypes.number,
  className: PropTypes.string
}
Spinner.defaultProps = {
  size: 'small',
  color: 'accent',
  delay: 100
}
