import { useEffect } from 'react'
import { useNavigate } from '@reach/router'
import { parse } from 'query-string'
import PropTypes from 'prop-types'
import {
  isIOS,
  isAndroid
} from 'react-device-detect'

import {
  iOS_DEEP_LINK_PROTOCOL,
  ANDROID_DEEP_LINK_PROTOCOL
} from '../../constants/config'

export default function UserLoginInfoPage ({ location }) {
  const navigate = useNavigate()

  useEffect(() => {
    const query = parse(location && location.search)
    const { id, pass } = query

    const link = `signup?id=${id}&pass=${pass}`

    const _handleRedirect = () => {
      if (isIOS) {
        window.location.replace(`${iOS_DEEP_LINK_PROTOCOL}${link}`)
      } else if (isAndroid) {
        window.location.replace(`${ANDROID_DEEP_LINK_PROTOCOL}${link}`)
      } else {
        navigate('/not_found')
      }
    }

    if (!id || !pass) {
      navigate('/not_found')
    } else {
      _handleRedirect()
    }
  }, [location, navigate])

  return null
}

UserLoginInfoPage.propTypes = {
  location: PropTypes.object
}
