
import styled from 'styled-components'
import { COLORS } from '../../constants'
import { CustomText } from '../../components/Display/Typography/Typography'

export const CustomTextTitle = styled(CustomText)`
    color: ${COLORS.DANGER};
`
export const CustomTextRecommended = styled(CustomText)`
    color: ${COLORS.INFO};
`
export const CustomTextLabel = styled(CustomText)`
    color: ${COLORS.PAGE_TITLE};
`
