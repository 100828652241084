import styled from 'styled-components'
import { Spin } from 'antd'
import { getColor } from '../../../constants'

export const SpinnerStyle = styled(Spin)`
  && {
    color: ${props => props.color ? getColor(props, props.color) : getColor(props, 'text')};
    .ant-spin-dot-item {
      background-color: ${props => props.color ? getColor(props, props.color) : getColor(props, 'text')};
    }
  }
`

// 'white', 'accent', 'info', 'success', 'danger', 'warning', 'text'
