import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { CloseOutlined } from '@ant-design/icons'

import {
  PrimaryButtonStyle,
  InfoButtonStyle,
  SuccessButtonStyle,
  DangerButtonStyle,
  CustomButtonStyle,
  RemoveContainerStyle,
  LoadingButtonStyle,
  SpinnerStyle
} from './Buttons.style'

const Button = ({ label, icon, reverse, handleClick, btnStyle, color, loading, ...props }) => {
  const { t } = useTranslation()
  if (loading) {
    return (
      <LoadingButtonStyle
        reverse={reverse}
        onClick={(e) => e.preventDefault()}
        {...props}
      >
        {icon ? <span>{icon}</span> : null}
        {t(label)}
        <SpinnerStyle
          color="text"
          size="small"
        />
      </LoadingButtonStyle>
    )
  }
  switch (btnStyle) {
    case 'primary':
      return (
        <PrimaryButtonStyle
          reverse={reverse}
          onClick={handleClick}
          {...props}
        >
          {icon ? <span>{icon}</span> : null}
          {t(label)}
        </PrimaryButtonStyle>
      )
    case 'info':
      return (
        <InfoButtonStyle
          reverse={reverse}
          onClick={handleClick}
          {...props}
        >
          {icon ? <span>{icon}</span> : null}
          {t(label)}
        </InfoButtonStyle>
      )
    case 'success':
      return (
        <SuccessButtonStyle
          reverse={reverse}
          onClick={handleClick}
          {...props}
        >
          {icon ? <span>{icon}</span> : null}
          {t(label)}
        </SuccessButtonStyle>
      )
    case 'danger':
      return (
        <DangerButtonStyle
          reverse={reverse}
          onClick={handleClick}
          {...props}
        >
          {icon ? <span>{icon}</span> : null}
          {t(label)}
        </DangerButtonStyle>
      )
    case 'custom':
      return (
        <CustomButtonStyle
          color={color}
          reverse={reverse}
          onClick={handleClick}
          {...props}
        >
          {icon ? <span>{icon}</span> : null}
          {t(label)}
        </CustomButtonStyle>
      )
    default:
      return null
  }
}

export const PrimaryButton = (props) => {
  return <Button btnStyle="primary" {...props} />
}
export const InfoButton = (props) => {
  return <Button btnStyle="info" {...props} />
}
export const SuccessButton = (props) => {
  return <Button btnStyle="success" {...props} />
}
export const DangerButton = (props) => {
  return <Button btnStyle="danger" {...props} />
}
export const CustomButton = (props) => {
  return <Button btnStyle="custom" {...props} />
}
export const RemoveButton = ({ label, handleClick }) => {
  return (
    <RemoveContainerStyle onClick={handleClick}>
      <CloseOutlined />
      {/* <RemoveTextStyle label={label} /> */}
    </RemoveContainerStyle>
  )
}

CustomButton.propTypes = {
  color: PropTypes.string.isRequired
}
RemoveButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func.isRequired
}

Button.propTypes = {
  btnStyle: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  reverse: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.node,
  loading: PropTypes.bool
}
Button.defaultProps = {
  reverse: false,
  icon: null
}
