
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { BlockStyle } from './Block.style'
import { SPACING_SIZES } from '../../../constants'

const Block = ({ direction, children, pt, pl, pb, pr, px, py, mt, ml, mb, mr, mx, my, justify, align, wrap, ...props }) => {
  return (
    <BlockStyle
      pt={pt}
      pl={pl}
      pb={pb}
      pr={pr}
      px={px}
      py={py}
      mt={mt}
      ml={ml}
      mb={mb}
      mr={mr}
      mx={mx}
      my={my}
      justify={justify}
      align={align}
      wrap={wrap}
      direction={direction}
      { ...props }
    >
      {children}
    </BlockStyle>
  )
}

const RowComponent = (props) => {
  return <Block direction="row" {...props} />
}
const ColumnComponent = (props) => {
  return <Block direction="column" {...props} />
}
export const Row = memo(RowComponent)
export const Column = memo(ColumnComponent)

Block.propTypes = {
  direction: PropTypes.string.isRequired,
  children: PropTypes.node,
  pt: PropTypes.oneOf(SPACING_SIZES),
  pl: PropTypes.oneOf(SPACING_SIZES),
  pb: PropTypes.oneOf(SPACING_SIZES),
  pr: PropTypes.oneOf(SPACING_SIZES),
  px: PropTypes.oneOf(SPACING_SIZES),
  py: PropTypes.oneOf(SPACING_SIZES),
  mt: PropTypes.oneOf(SPACING_SIZES),
  ml: PropTypes.oneOf(SPACING_SIZES),
  mb: PropTypes.oneOf(SPACING_SIZES),
  mr: PropTypes.oneOf(SPACING_SIZES),
  mx: PropTypes.oneOf(SPACING_SIZES),
  my: PropTypes.oneOf(SPACING_SIZES),
  justify: PropTypes.string,
  align: PropTypes.string,
  wrap: PropTypes.string
}

Row.defaultProps = {

}
