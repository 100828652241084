// import moment from 'moment'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { parseUrl } from 'query-string'

const getFileExtension = (filename) => {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
}

export const handleFile = ({
  file,
  type,
  maxSize = 5
}) => {
  const sizeLimit = maxSize * 1000000
  if (!file) return

  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    // Check file type
    let acceptedTypes
    switch (type) {
      case 'image':
        acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
        break
      case 'pdf':
        acceptedTypes = ['application/pdf']
        break
      case 'doc':
        acceptedTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.ms-word.document.macroenabled.12']
        break
      case 'xls':
        acceptedTypes = ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroenabled.12', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        break
      case 'zip':
        acceptedTypes = ['application/zip']
        break
      case 'csv':
        acceptedTypes = ['text/csv', 'application/vnd.ms-excel']
        break
      default:
        break
    }

    if (!acceptedTypes.includes(file.type)) {
      reject(new Error('対応可能なファイル外を選択しました。\n確認の上でもう一度ファイルを選択してください。'))
    }

    // Check file size
    if (file.size > sizeLimit) {
      reject(new Error(`ファイルは${maxSize}MBを超えています。\n確認の上でもう一度ファイルを選択してください。`))
    }
    reader.onerror = () => {
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = () => {
      resolve({
        file,
        fileURL: reader.result
      })
    }
    reader.readAsDataURL(file)
  })
}

export const getFileFromURL = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(url, { responseType: 'blob' })
      if (res && res.data) {
        resolve(res.data)
      } else {
        reject(new Error('Error fecthing file from url...'))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const downloadImage = async (src, filename) => {
  if (src) {
    let extension = 'jpeg'
    const parsedURL = parseUrl(src).url
    if (parsedURL && getFileExtension(parsedURL)) {
      extension = getFileExtension(parsedURL)
    }
    // const parsedExtension = src.match(/\.(\w+)\?/i)
    // let extension = (parsedExtension && parsedExtension[1]) || 'jpeg'
    // if (extension.toLowerCase() === 'jpg') extension = 'jpeg'
    try {
      const file = await getFileFromURL(src)
      saveAs(file, `${filename}.${extension}`)
    } catch (error) {
      console.warn(error)
    }
  }
}
