
import styled from 'styled-components'

export const CenteredStyle = styled.div`
  height: ${props => props.fullScreen ? '100vh' : '100%'};
  width: ${props => props.fullScreen ? '100vw' : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
`
