import styled from 'styled-components'

export const ImageStyle = styled.div`
    height: ${props => props.full ? '100%' : props.h};
    width: ${props => props.full ? '100%' : props.w};
    display: block;
    cursor: ${props => props.previewable ? 'pointer' : 'default'};
    img{
        display: block;
        max-width: 100%;
        max-height: 100%;
        width:${props => {
            if (props.cover || props.contain) return '100%'
            return 'auto'
        }};
        height:${props => {
            if (props.cover || props.contain) return '100%'
            return 'auto'
        }};
        object-fit:${props => {
            if (props.cover) return 'cover'
            if (props.contain) return 'contain'
            return 'fill'
        }};
        object-position: center;
    }
`
