import React, { useEffect } from 'react'
import axios from 'axios'
import { isIOS } from 'react-device-detect'
import PropTypes from 'prop-types'

function AppDownload ({ app_id }) {
  useEffect(() => {
    const getDownloadURL = async () => {
      const res = await axios.get(`${process.env.REACT_APP_APPLI_API_URL}/download?app_id=${app_id}&os=${isIOS ? 1 : 2}`)
      if (res.data.download_url) {
        window.location.replace(res.data.download_url)
      }
    }

    if (app_id) {
      getDownloadURL()
    }
  }, [app_id])

  return <p>リダイレクト中、しばらくお待ち下さい...</p>
}

AppDownload.propTypes = {
  app_id: PropTypes.string
}

export default AppDownload
