import React from 'react'

import Centered from '../../components/Layout/Centered'
import Card from '../../components/Layout/Card'
import { Column } from '../../components/Layout/Block'

import {
  TitleStyle,
  SvgContainerStyle
} from './UserVerifySuccessPage.style'

const {
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG
} = process.env

export default function UserVerifySuccessPage () {
  return (
    <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
      <Card w={320} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
        <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
          <Column px={'M'} pt={'M'} pb={'M'}>
            <TitleStyle label={'ありがとうございます'}/>
            <SvgContainerStyle>
              <svg version="1.1" id="_x32_" x="0px" y="0px" style={{ width: '40px' }} viewBox="0 0 512 512" xmlSpace="preserve">
                <polygon className="st0" points="440.469,73.413 218.357,295.525 71.531,148.709 0,220.229 146.826,367.055 218.357,438.587 289.878,367.055 512,144.945 " style={{ fill: '#f4a800' }}></polygon>
              </svg>
            </SvgContainerStyle>
            <p>
                承認が成功しました。
              <br/>
                アプリを再度起動してください。
            </p>
          </Column>
        </Centered>
      </Card>
    </Centered>
  )
}
