import styled from 'styled-components'
import { FONT_SIZE, COLORS, SPACING } from '../../constants'

export const LayoutStyle = styled.div`
    width: calc(100% - ${SPACING.LARGEST}rem);
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: ${SPACING.LARGE}rem;
`

export const TitleStyle = styled.h1`
    ${FONT_SIZE.LARGE}
    color: ${COLORS.accent};
`

export const InputContainerStyle = styled.div`
    margin: ${SPACING.MEDIUM_LARGE}rem auto;
    position: relative;
`
export const SelectContainerStyle = styled.div`
    margin: ${SPACING.MEDIUM_LARGE}rem auto;
    width: 100%;
    position: relative;
    &::after {
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 2px ${COLORS.pageTitle};
        border-right: solid 2px ${COLORS.pageTitle};
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
  }
`

export const SubmitContainerStyle = styled.div`
    width: 100%;
    margin-top: ${SPACING.LARGE}rem;
`

export const SelectStyle = styled.select`
    width: 100%;
    border: none;
    border-bottom: 1px solid ${COLORS.pageTitle};
    background-color: ${COLORS.white};
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    ${FONT_SIZE.MEDIUM};
    outline: none;
`

export const InputSelect = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid ${COLORS.pageTitle};
    background-color: ${COLORS.white};
    border-radius: 0;
    min-height: 24px;
    ${FONT_SIZE.MEDIUM};
    outline: none;
`

export const SubmitStyle = styled.button`
    width: 100%;
    border: 1px solid ${COLORS.accent};
    border-radius: 4px;
    background-color: ${COLORS.accent};
    color: ${COLORS.white};
    box-sizing: border-box;
    padding: 8px;
    &:active{
        background-color: ${COLORS.white};
        color: ${COLORS.accent};
    }
    &:disabled {
        background-color: ${COLORS.lightText};
        border-color: ${COLORS.lightText};
        color: ${COLORS.white};
    }
    span {
        margin-right: ${SPACING.SMALL}rem;
    }
`

export const ErrorMessageStyle = styled.p`
    position: absolute;
    bottom: -1.4rem;
    left: 0;
    right: 0;
    background-color :#D33F3F20;
    color :#D33F3F;
    margin: 0;
`
export const DatePickerStyle = styled(InputSelect)`
    &&::before {
        color: #999999;
        content: attr(placeholder);
    }
    && {
        color: #ffffff;
    }
    &&:focus,
    &&:valid {
        color: #666666;
    }
    &&:focus::before,
    &&:valid::before {
        content: "" !important;
    }
`

export const TableTitleStyle = styled.h2`
    ${FONT_SIZE.MEDIUM}
    color: ${COLORS.accent};
`
export const TableTdTitleStyle = styled.h3`
    ${FONT_SIZE.SMALL}
    color: ${COLORS.accent};
`

export const TableTdtextStyle = styled.p`
    ${FONT_SIZE.SMALL}
    margin: ${SPACING.SMALLEST}rem 0;
`

export const TableContainerStyle = styled.div`
    margin-top: ${SPACING.LARGE}rem;
    overflow-x: scroll;
`

export const ListContentStyle = styled.div`
    width: 118px;
`

export const TableStyle = styled.table`
    table-layout: fixed;
    border-collapse: separate;
    border-spacing : ${SPACING.REGULAR}rem;
`

export const ListDivStyle = styled.div`
    border: 1px solid ${COLORS.accent};
    border-radius: 4px;
    padding: ${SPACING.REGULAR}rem;
    margin-bottom: ${SPACING.REGULAR}rem;
`

export const ListFlexStyle = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
