import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Spinner from '../../Feedback/Spinner'
import {
  PageLoaderStyle
} from './PageLoader.style.js'

const PageLoader = ({ color }) => {
  return (
    <PageLoaderStyle>
      <Spinner
        size="large"
        description="loading"
        color={color}
      />
    </PageLoaderStyle>
  )
}
export default memo(PageLoader)

PageLoader.propTypes = {
  color: PropTypes.oneOf(['accent', 'info', 'success', 'danger', 'warning', 'text'])
}
PageLoader.defaultProps = {
  color: 'info'
}
