
import styled from 'styled-components'

import {
  Title
} from '../../components/Display/Typography'
import Image from '../../components/Display/Image'

import {
  HEAVY_BOX_SHADOW,
  FONT_SIZE,
  SPACING
} from '../../constants'

import {
  PrimaryButton,
  DangerButton
} from '../../components/Display/Buttons'

export const TitleStyle = styled(Title)`
    ${FONT_SIZE.LARGE}
`

export const PrimaryButtonStyle = styled(PrimaryButton)`
    width: 100%;
    padding: ${SPACING.SMALL}rem; 
`

export const DangerButtonStyle = styled(DangerButton)`
    width: 100%;
    padding: ${SPACING.SMALL}rem; 
`

export const ImageStyle = styled(Image)`
    ${HEAVY_BOX_SHADOW}
    border-radius: 50%;
    img{
     border-radius: 50%;
    }
`
