import styled from 'styled-components'
import { SPACING_LEVEL } from '../../../constants'

const alignType = type => {
  switch (type) {
    case 'start':
      return 'flex-start'
    case 'end':
      return 'flex-end'
    case 'baseline':
      return 'baseline'
    default:
      return 'center'
  }
}

const justifyType = type => {
  switch (type) {
    case 'start':
      return 'flex-start'
    case 'end':
      return 'flex-end'
    case 'center':
      return 'center'
    case 'between':
      return 'space-between'
    case 'around':
      return 'space-around'
    default:
      return 'normal'
  }
}

const wrapType = type => {
  switch (type) {
    case 'wrap':
      return 'wrap'
    case 'reverse':
      return 'wrap-reverse'
    default:
      return 'normal'
  }
}

export const BlockStyle = styled.div`
    padding-top: ${props => props.py ? SPACING_LEVEL(props.py) : SPACING_LEVEL(props.pt)}rem;
    padding-bottom: ${props => props.py ? SPACING_LEVEL(props.py) : SPACING_LEVEL(props.pb)}rem;
    padding-right: ${props => props.px ? SPACING_LEVEL(props.px) : SPACING_LEVEL(props.pr)}rem;
    padding-left: ${props => props.px ? SPACING_LEVEL(props.px) : SPACING_LEVEL(props.pl)}rem;
    margin-top: ${props => props.my ? SPACING_LEVEL(props.my) : SPACING_LEVEL(props.mt)}rem;
    margin-bottom: ${props => props.my ? SPACING_LEVEL(props.my) : SPACING_LEVEL(props.mb)}rem;
    margin-right: ${props => props.mx ? SPACING_LEVEL(props.mx) : SPACING_LEVEL(props.mr)}rem;
    margin-left: ${props => props.mx ? SPACING_LEVEL(props.mx) : SPACING_LEVEL(props.ml)}rem;
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: ${props => alignType(props.align)};
    justify-content: ${props => justifyType(props.justify)};
    flex-wrap: ${props => wrapType(props.wrap)};
    width: 100%;
`
