import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import i18n from '../../locales/i18n'

import {
  CHROME_IMG,
  EDGE_IMG,
  FIREFOX_IMG
} from '../../constants/images'

import Centered from '../../components/Layout/Centered'
import { Row, Column } from '../../components/Layout/Block'
import {
  CardTitle
} from '../../components/Display/Typography/Typography'
import Image from '../../components/Display/Image/Image'
import {
  CustomButton
} from '../../components/Display/Buttons/Buttons'

import {
  CustomTextTitle,
  CustomTextRecommended,
  CustomTextLabel
} from './InternetExplorerPage.style'
import { SPACING, COLORS } from '../../constants'

const InternetExplorerPage = (props) => {
  const _handleClick = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Centered fullScreen>
      <Column>
        <Row justify={'center'}>
          <CustomTextTitle
            text={i18n.t('internet_explorer:not_support')}
            textColor={COLORS.DANGER}
            textSize={SPACING.LARGER}
          />
        </Row>
        <Row justify={'center'}>
          <CardTitle text={i18n.t('internet_explorer:detail_1')}/>
        </Row>
        <Row justify={'center'}>
          <CardTitle text={i18n.t('internet_explorer:detail_2')} />
        </Row>
        <Row justify={'center'} mt={'L'}>
          <CustomTextRecommended
            text={i18n.t('internet_explorer:recommended')}
            textColor={COLORS.INFO}
            textSize={SPACING.LARGER}
          />
        </Row>
        <Row justify={'around'} mt={'L'}>
          <Column>
            <Image src={CHROME_IMG} w={'40px'} h={'40px'} />
            <Row justify={'around'} mt={'R'}>
              <CustomTextLabel
                text={i18n.t('Google Chrome')}
                textColor={COLORS.PAGE_TITLE}
                textSize={SPACING.LARGE}
              />
            </Row>
            <Row justify={'around'} mt={'R'}>
              <CustomButton
                label={i18n.t('internet_explorer:download')}
                reverse
                handleClick={() => _handleClick('https://www.google.com/chrome/')}
                color={COLORS.ACCENT}
              />
            </Row>
          </Column>
          <Column>
            <Image src={EDGE_IMG} w={'40px'} h={'40px'} />
            <Row justify={'around'} mt={'R'}>
              <CustomTextLabel
                text={i18n.t('internet_explorer:download')}
                textColor={COLORS.PAGE_TITLE}
                textSize={SPACING.LARGE}
              />
            </Row>
            <Row justify={'around'} mt={'R'}>
              <CustomButton
                label={i18n.t('internet_explorer:download')}
                reverse
                handleClick={() => _handleClick('https://www.microsoft.com/ja-jp/edge')}
                color={COLORS.ACCENT}
              />
            </Row>
          </Column>
          <Column>
            <Image src={FIREFOX_IMG} w={'40px'} h={'40px'} />
            <Row justify={'around'} mt={'R'}>
              <CustomTextLabel
                text={i18n.t('Firefox Browser')}
                textColor={COLORS.PAGE_TITLE}
                textSize={SPACING.LARGE}
              />
            </Row>
            <Row justify={'around'} mt={'R'}>
              <CustomButton
                label={i18n.t('ダウンロード')}
                reverse
                handleClick={() => _handleClick('https://www.mozilla.org/ja/firefox/new/')}
                color={COLORS.ACCENT}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </Centered>
  )
}
export default memo(InternetExplorerPage)

InternetExplorerPage.propTypes = {

}
InternetExplorerPage.defaultProps = {

}
