import styled from 'styled-components'
import { FONT_SIZE, COLORS, SPACING } from '../../constants'

export const LayoutStyle = styled.div`
    width: calc(100% - ${SPACING.LARGEST}rem);
    margin: 0 auto;
    padding: 48px 0;
`

export const TitleStyle = styled.h1`
    ${FONT_SIZE.LARGE}
    color: ${COLORS.accent};
`

export const CardStyle = styled.div`
    box-sizing: border-box;
    padding: ${SPACING.REGULAR}rem;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ${SPACING.MEDIUM_LARGE}rem;
`

export const CardTitleStyle = styled.p`
    ${FONT_SIZE.MEDIUM}
    margin-bottom: ${SPACING.SMALLEST}rem;
    color: ${COLORS.TitleStyle};
    font-weight: 500;
`

export const CountStyle = styled.p`
    margin: 0;
    ${FONT_SIZE.LARGE}
    color: ${COLORS.accent};
    font-weight: bold;
    > span{
        ${FONT_SIZE.MEDIUM}
        color: ${COLORS.pageTitle};
        font-weight: normal;
    }
`

export const SubTitleStyle = styled.p`
    color: ${COLORS.accent};
    ${FONT_SIZE.MEDIUM};
    margin-bottom: ${SPACING.SMALLEST}rem;
`

export const StatusContainerStyle = styled.div`
    border: 1px solid ${COLORS.accent};
    border-radius: 4px;
    padding: ${SPACING.SMALL}rem;
`

export const StatusContentStyle = styled.div`
    display: flex;
    margin: ${SPACING.SMALLEST}rem 0;
`

export const StatusTextStyle = styled.p`
    ${FONT_SIZE.MEDIUM}
    margin: 0 ${SPACING.SMALLEST}rem;
    color: ${COLORS.TitleStyle};
    font-weight: 500;
`

export const SvgContainerStyle = styled.div`
    width: ${SPACING.LARGE}rem;
    margin-right: ${SPACING.REGULAR}rem;
`
