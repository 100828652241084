import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import commonSettings from './commonSettings'
const { resources, ns } = commonSettings

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_DEBUG_MODE === 'true',
    lng: process.env.REACT_APP_DEFAULT_LOCALE || 'ja',
    fallbackLng: 'ja', // use ja if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false,
      wait: true
    },
    resources,
    // have a common namespace used around the full app
    ns,
    defaultNS: 'common'
  })

export default i18n
