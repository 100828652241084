import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { message } from 'antd'
import {
  isDesktop,
  isIOS,
  isAndroid
} from 'react-device-detect'
import { useNavigate } from '@reach/router'

import configureAxios from '../../utils/configureAxios'
import {
  ANDROID_DEEP_LINK_PROTOCOL,
  iOS_DEEP_LINK_PROTOCOL
} from '../../constants/config'

import PageLoader from '../../components/Layout/PageLoader'

const api = configureAxios()

function UserVerify ({ location }) {
  const navigate = useNavigate()
  const [loading, setIsLoading] = useState(true)

  const verifyToken = useCallback(
    async (token) => {
      if (token) {
        const link = 'verify'
        try {
          await api.post('/app_users/verify_token', { token })
          if (isIOS) window.location.replace(`${iOS_DEEP_LINK_PROTOCOL}${link}`)
          if (isAndroid) window.location.replace(`${ANDROID_DEEP_LINK_PROTOCOL}${link}`)
          if (isDesktop) navigate('/app_user/verify/success')
        } catch (error) {
          message.error('認証エラー')
        }
      } else {
        message.error('パラメーターエラー')
      }
      setIsLoading(false)
    },
    [navigate]
  )

  useEffect(() => {
    const query = parse(location && location.search)
    const { token } = query
    verifyToken(token)
  }, [location, verifyToken])

  if (loading) {
    return <PageLoader />
  }

  // return <Redirect to="/not_found" noThrow />

  return null
}

UserVerify.propTypes = {
  location: PropTypes.object
}

export default UserVerify
