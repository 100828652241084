import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

function AppChangePassword ({ scheme, appKey }) {
  useEffect(() => {
    if (scheme && appKey) {
      const url = `${scheme}://password?key=${appKey}`
      window.location.replace(url)
    }
  }, [scheme, appKey])

  return <p>リダイレクト中、しばらくお待ち下さい...</p>
}

AppChangePassword.propTypes = {

}

export default AppChangePassword

AppChangePassword.propTypes = {
  scheme: PropTypes.string,
  appKey: PropTypes.string
}
