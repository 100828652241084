import React, { memo } from 'react'
import PropTypes from 'prop-types'

const ErrorInfo = ({ error, errorInfo, eventId }) => {
  if (process.env.REACT_APP_STAGE === 'production') {
    // TODO: redirect to error page
    return <h1>Oops, Something went wrong...</h1>
  }
  return (
    <div>
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {errorInfo.componentStack}
      </details>
      <small>{eventId}</small>
    </div>
  )
}

export default memo(ErrorInfo)

ErrorInfo.propTypes = {
  eventId: PropTypes.string,
  error: PropTypes.object.isRequired,
  errorInfo: PropTypes.object.isRequired
}
