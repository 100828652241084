import React from 'react'
import PropTypes from 'prop-types'
import { CardStyle, CardTitleStyle, CountStyle } from './InuiStatus.style'

function Card ({ title, status, count }) {
  const renderStatus = () => {
    switch (status) {
      case 0:
        return (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30.536" height="24" viewBox="0 0 30.536 24">
              <g transform="translate(-57 -662.262)">
                <g transform="translate(-107.947 662.262)">
                  <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#d7263d"/>
                  <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#d7263d"/>
                </g>
                <g transform="translate(-96.947 662.262)">
                  <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#d7263d"/>
                  <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#d7263d"/>
                </g>
                <g transform="translate(-85.947 662.262)">
                  <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#d7263d"/>
                  <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#d7263d"/>
                </g>
              </g>
            </svg>
          </div>
        )
      case 1:
        return (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.536" height="24" viewBox="0 0 19.536 24">
              <g transform="translate(-57 -698.262)">
                <g transform="translate(-107.947 698.262)">
                  <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#f29438"/>
                  <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#f29438"/>
                </g>
                <g transform="translate(-96.947 698.262)">
                  <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#f29438"/>
                  <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#f29438"/>
                </g>
              </g>
            </svg>
          </div>
        )
      case 2:
        return (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.536" height="24" viewBox="0 0 8.536 24">
              <g transform="translate(-164.947)">
                <path d="M210.509,4.577a2.262,2.262,0,0,0,2.235-2.288,2.236,2.236,0,1,0-4.47,0A2.262,2.262,0,0,0,210.509,4.577Z" transform="translate(-41.271 0)" fill="#1890ff"/>
                <path d="M171.2,119.275h-3.963a2.536,2.536,0,0,0-2.287,2.278v7.568a.99.99,0,0,0,.991.988h.692l.377,6.454a1.053,1.053,0,0,0,1.055,1.051h2.305a1.053,1.053,0,0,0,1.055-1.051l.377-6.454h.692a.99.99,0,0,0,.991-.988v-7.568A2.536,2.536,0,0,0,171.2,119.275Z" transform="translate(0 -113.614)" fill="#1890ff"/>
              </g>
            </svg>
          </div>
        )
      case 3:
        return (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM4.668,6.79,17.21,19.332A8.993,8.993,0,0,1,4.668,6.79ZM19.331,17.21,6.79,4.668A8.992,8.992,0,0,1,19.331,17.21Z" fill="#d7263d"/>
            </svg>
          </div>
        )
      default:
        return null
    }
  }
  return (
    <CardStyle>
      <div>
        <CardTitleStyle>{title}</CardTitleStyle>
        {renderStatus()}
      </div>
      <div>
        {status === 3 ? (
          <CountStyle><span>閉鎖</span></CountStyle>
        ) : (
          <CountStyle>{count}<span>人</span></CountStyle>
        )}
      </div>
    </CardStyle>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  status: PropTypes.number,
  count: PropTypes.number
}

export default Card
