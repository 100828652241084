import styled, { keyframes } from 'styled-components'
import { transparentize } from 'polished'
import { fadeIn } from 'react-animations'
import { SPACING, BORDER_RADIUS } from '../../../constants'
import { Row } from '../../Layout/Block'

const fadeInAnimation = keyframes`${fadeIn}`

export const ModalOuterStyle = styled.div`
  z-index: 22;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => transparentize(0.5, props.theme.colors.pageTitle)};
  overflow: hidden;
  animation: 1s ${fadeInAnimation};
`
export const ModalMaskStyle = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`
export const ModalStyle = styled.div`
  ${BORDER_RADIUS};
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.colors.white};
  max-width: 660px;
  max-height: 660px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
export const ModalTitleStyle = styled.div`
  padding: ${SPACING.MEDIUM}rem ${SPACING.REGULAR}rem;
  border-bottom: 1px solid ${props => props.theme.colors.cardBorder};
`
export const ModalBodyStyle = styled.div`
  padding: ${SPACING.REGULAR}rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const ModalFooterStyle = styled(Row)`
  padding: ${SPACING.SMALLEST}rem ${SPACING.REGULAR}rem;
  border-top: 1px solid ${props => props.theme.colors.cardBorder};
  background-color: ${props => props.theme.colors.white};

  button:not(:last-of-type) {
    margin-right: ${SPACING.SMALLEST}rem;
  }
`
