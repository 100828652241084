import CryptoJS from 'crypto-js'
const SECRET_KEY = 'DRFT5678TYUG#$POIOINBNCFGXDF'

export const encrypt = function (messageToEncrypt) {
  return CryptoJS.AES.encrypt(messageToEncrypt, SECRET_KEY).toString()
}

export const decrypt = function (encryptedMessage) {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, SECRET_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}
