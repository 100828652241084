import React, { memo, useEffect } from 'react'
import { Router, LocationProvider, createHistory } from '@reach/router'
import { GAListener } from '../utils/analytics'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import NotFoundPage from '../pages/NotFoundPage'
import ApprovalPage from '../pages/ApprovalPage'
import OikuraInfo from '../pages/OikuraInfo'
import Agreements from '../pages/Agreements'
import AppDownload from '../pages/AppDownload'
import AppChangePassword from '../pages/AppChangePassword'
import UserLoginInfoPage from '../pages/UserLoginInfoPage'
import UserVerifyPage from '../pages/UserVerifyPage'
import UserVerifySuccessPage from '../pages/UserVerifySuccessPage'
import InuiContact from '../pages/InuiContact'
import InuiStatus from '../pages/InuiStatus'
import UserResetPage from '../pages/UserResetPage'

const history = createHistory(window)
GAListener(history)

const Routes = () => {
  const { i18n } = useTranslation()
  let locale = ''
  if (window.navigator.languages) {
    locale = window.navigator.languages[0]
  } else {
    locale = window.navigator.userLanguage || window.navigator.language
  }

  // console.log(`%c rendering router, ${locale}`, 'background: #222; color: #bada55')
  useEffect(() => {
    if (locale) {
      console.log(`%c loading locale... ${locale}`, 'background: #222; color: #aa55ff')
      i18n.changeLanguage(locale)
      switch (locale) {
        case 'ja':
          require('dayjs/locale/ja')
          dayjs.locale('ja')
          break
        case 'en':
          require('dayjs/locale/en')
          dayjs.locale('en')
          break
        default:
          require('dayjs/locale/ja')
          dayjs.locale('ja')
          break
      }
    }
  }, [locale, i18n])

  return (
    <LocationProvider history={history}>
      <Router>
        <OikuraInfo exact path="/services/oikura/info"/>
        <Agreements exact path="/agreements"/>
        <AppDownload exact path="/dl/:app_id"/>
        <AppChangePassword exact path="/app_change_password/:scheme/:appKey"/>
        <ApprovalPage path="/user_approval"/>
        <UserLoginInfoPage path="/app_user/tmp_login"/>
        <UserVerifyPage path="/app_user/verify"/>
        <UserVerifySuccessPage path="/app_user/verify/success"/>
        <UserResetPage path="/app_user/reset"/>
        <InuiContact path="/inui/contact"/>
        <InuiStatus path="/inui/status"/>
        <NotFoundPage default />
      </Router>
    </LocationProvider>
  )
}

export default memo(Routes)
