
import styled from 'styled-components'

import {
  Title
} from '../../components/Display/Typography'

import {
  COLORS,
  FONT_SIZE,
  SPACING
} from '../../constants'

import {
  PrimaryButton
} from '../../components/Display/Buttons'

export const TitleStyle = styled(Title)`
    ${FONT_SIZE.LARGE}
`

export const PrimaryButtonStyle = styled(PrimaryButton)`
    width: 100%;
    padding: ${SPACING.SMALL}rem;
`

export const FormStyle = styled.form`
  width: 100%;
`

export const InputContainerStyle = styled.div`
    margin: ${SPACING.MEDIUM_LARGE}rem auto;
    position: relative;
`

export const TextInput = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid ${COLORS.pageTitle};
    background-color: ${COLORS.white};
    border-radius: 0;
    min-height: 24px;
    ${FONT_SIZE.MEDIUM};
    outline: none;
`

export const ErrorMessageStyle = styled.p`
    ${FONT_SIZE.SMALL};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color :#D33F3F20;
    color :#D33F3F;
    margin: 0;
    padding: 2px 4px;
    transform: translateY(100%);
`
