import React from 'react'
import { isIE } from 'react-device-detect'

import Router from './Routes/Router'
import { GlobalStyle } from './components/Misc/GlobalStyle'

import ErrorBoundary from './components/Misc/ErrorBoundary'
import Helmet from './components/Misc/Helmet'
import InternetExplorerPage from './pages/InternetExplorerPage'

const App = () => {
  if (isIE) return <InternetExplorerPage />

  return (
    <ErrorBoundary>
      <Helmet />
      <GlobalStyle />
      <Router />
    </ErrorBoundary>
  )
}

export default App
