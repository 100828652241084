import styled from 'styled-components'

import {
  Title
} from '../../components/Display/Typography'

import {
  FONT_SIZE,
  SPACING
} from '../../constants'

export const TitleStyle = styled(Title)`
      ${FONT_SIZE.LARGE}
  `

export const SvgContainerStyle = styled.div`
    margin: ${SPACING.SMALL}rem 0;
`
