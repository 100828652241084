import { css } from 'styled-components'

// Font sizes in REM
export const FONT_SIZE = {
  LARGER: () => css`
    font-size: 2rem;
  `, // 32px
  LARGE: () => css`
    font-size: 1.5rem;
  `, // 24px
  MEDIUM: () => css`
    font-size: 1rem;
  `, // 16px
  REGULAR: () => css`
    font-size: 0.875rem;
  `, // 14px
  SMALL: () => css`
    font-size: 0.75rem;
  ` // 12px
}

export const SPACING = {
  SMALLEST: 0.5, // 8px
  SMALL: 0.75, // 12px
  REGULAR: 1, // 16px
  MEDIUM: 1.25, // 20px
  MEDIUM_LARGE: 1.5, // 24PX
  LARGE: 2, // 32px
  LARGER: 3.5, // 56PX
  LARGEST: 4 // 65PX
}

export const COLORS = {
  white: '#FFFFFF',
  inputBorder: '#D9D9D9',
  cardBorder: '#EBEDF0',
  accent: '#F4A800',
  info: '#1890FF',
  success: '#52C41A',
  danger: '#D33F3F',
  warning: '#FFCC00',
  text: '#666666',
  lightText: '#999999',
  pageTitle: '#0D1A26',
  file: '#D9D9D9',
  pdf: 'rgb(220,39,28)',
  csv: '#1D6F42',
  doc: '#3d5697',
  ppt: '#D04423',
  txt: '#999999',
  video: '#0D1A26'
}

export const SPACING_LEVEL = level => {
  switch (level) {
    case 'XS':
      return SPACING.SMALLEST
    case 'S':
      return SPACING.SMALL
    case 'R':
      return SPACING.REGULAR
    case 'M':
      return SPACING.MEDIUM
    case 'ML':
      return SPACING.MEDIUM_LARGE
    case 'L':
      return SPACING.LARGE
    case 'XL':
      return SPACING.LARGER
    case 'XXL':
      return SPACING.LARGEST
    default:
      return 0
  }
}

export const SPACING_SIZES = [
  'XS',
  'S',
  'R',
  'M',
  'ML',
  'L',
  'XL',
  'XXL'
]

export const BORDER_RADIUS = () => css`
  border-radius: 4px;
`
export const BOX_SHADOW = () => css`
  box-shadow: 0 2px 8px 0 #f0f1f2;
`
export const HEAVY_BOX_SHADOW = () => css`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`
export const NAVBAR_HEIGHT = SPACING.LARGEST
export const LEFT_SIDEBAR_WIDTH = 257

export const getColor = (props, color) => {
  if (!Object.keys(COLORS).includes(color)) throw new Error(`Color not found. Must be one of: ${Object.keys(COLORS)}, but got ${color}`)
  if (props && props.theme && props.theme.colors && props.theme.colors[color]) {
    return props.theme.colors[color]
  }
  return COLORS[color]
}
