import React from 'react'
import { Helmet } from 'react-helmet'

// TODO: Update head metadata
const AppHelmet = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>app-me!Cloud | Public</title>
      <link rel="canonical" href="https://public.appme-cloud.com" />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Red+Hat+Text&display=swap" rel="stylesheet"/>
    </Helmet>
  )
}

export default AppHelmet
