import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { DangerButton, InfoButton } from '../../Display/Buttons'

import {
  ModalOuterStyle,
  ModalMaskStyle,
  ModalStyle,
  ModalTitleStyle,
  ModalBodyStyle,
  ModalFooterStyle
} from './Modal.style.js'
import { circular } from '../../../utils'

const Modal = ({
  visible,
  okText,
  okIcon,
  cancelText,
  title,
  onCancel,
  onOk,
  children,
  className
}) => {
  const { t } = useTranslation()
  if (!visible) return null
  return (
    <ModalOuterStyle>
      <ModalMaskStyle onClick={onCancel}/>
      <ModalStyle className={className}>
        { title ? <ModalTitleStyle>{t(title)}</ModalTitleStyle> : null}
        <ModalBodyStyle>
          { children }
        </ModalBodyStyle>
        <ModalFooterStyle justify="end">
          <DangerButton type="button" label={cancelText || 'cancel' } handleClick={onCancel}/>
          { onOk ? <InfoButton type="button" reverse label={okText || 'ok' } handleClick={onOk} icon={okIcon}/> : null }
        </ModalFooterStyle>
      </ModalStyle>
    </ModalOuterStyle>
  )
}
export default memo(Modal, (prevProps, nextProps) => {
  const prev = JSON.stringify(prevProps, circular())
  const next = JSON.stringify(nextProps, circular())
  return prev === next
})

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  okText: PropTypes.string,
  okIcon: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string
}
Modal.defaultProps = {

}
