import styled from 'styled-components'
import { SPACING, FONT_SIZE, COLORS } from '../../../constants'

export const TitleStyle = styled.h1`
    ${FONT_SIZE.LARGER}
    font-weight: bold;
    margin: 0;
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.pageTitle) || COLORS.PAGE_TITLE};
    white-space: pre-wrap;
`
export const SectionTitleStyle = styled.h2`
    ${FONT_SIZE.LARGE}
    font-weight: normal;
    margin: 0;
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.text) || COLORS.TEXT};
    white-space: pre-wrap;
`
export const CardTitleStyle = styled.h3`
    ${FONT_SIZE.MEDIUM}
    font-weight: normal;
    margin: 0;
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.text) || COLORS.TEXT};
    white-space: pre-wrap;
`
export const ToastTitleStyle = styled.h3`
    ${FONT_SIZE.MEDIUM}
    font-weight: bold;
    margin: 0;
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.pageTitle) || COLORS.PAGE_TITLE};
    white-space: pre-wrap;
`
export const CommentTitleStyle = styled.h3`
    ${FONT_SIZE.MEDIUM}
    font-weight: normal;
    margin: 0;
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.pageTitle) || COLORS.PAGE_TITLE};
    white-space: pre-wrap;
`
export const RegularTextStyle = styled.span`
    ${FONT_SIZE.REGULAR}
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.text) || COLORS.TEXT};
    white-space: pre-wrap;
`
export const LightRegularTextStyle = styled.span`
    ${FONT_SIZE.REGULAR}
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.listText) || COLORS.LIST_TEXT};
    white-space: pre-wrap;
`
export const SmallTextStyle = styled.span`
    ${FONT_SIZE.SMALL}
    color: ${props => (props.theme && props.theme.colors && props.theme.colors.text) || COLORS.TEXT};
    white-space: pre-wrap;
`
export const CustomTextStyle = styled.span`
    font-size: ${props => props.textSize || SPACING.REGULAR};
    white-space: pre-wrap;
    color: ${props => props.textColor || COLORS.TEXT}
`
