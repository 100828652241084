import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { Options } from './InuiContact'
import DatePicker from './DatePicker'
import { InputContainerStyle, SelectContainerStyle, SelectStyle, InputSelect, ErrorMessageStyle } from './InuiContact.style.js'
import { INUI_CONTACT_TYPE, INUI_PROPERTIES } from '../../constants'

const selectPaymentMethodOptions = [
  {
    value: 1,
    label: '振り込み'
  },
  {
    value: 2,
    label: '口座振替'
  }
]
const selectParkingTypeOptions = [
  {
    value: 1,
    label: 'タワーパーキング・ノーマル'
  },
  {
    value: 2,
    label: 'タワーパーキング・ハイルーフ'
  },
  {
    value: 3,
    label: '二段式・上段'
  },
  {
    value: 4,
    label: '二段式・下段'
  },
  {
    value: 5,
    label: '平置き'
  }
]
const selectBikeTypeOptions = [
  {
    value: 1,
    label: '原付（50cc）'
  },
  {
    value: 2,
    label: 'バイク（50cc超）'
  }
]
const selectTrunkTypeOptions = [
  {
    value: 1,
    label: '大'
  },
  {
    value: 2,
    label: '小'
  }
]

function Form ({ property }) {
  const { watch, register, errors } = useFormContext()
  const contactType = watch('contact_type', 0)

  const _renderParkingOptions = () => {
    let options = selectParkingTypeOptions
    if (property === INUI_PROPERTIES.PLAZA_KACHIDOKI) {
      options = selectParkingTypeOptions.filter(option => option.value === 5)
    }
    return <Options options={options} />
  }
  const _renderPaymentOptions = () => {
    let options = selectPaymentMethodOptions
    if (property === INUI_PROPERTIES.TSUKISHIMA) {
      options = selectPaymentMethodOptions.filter(option => option.value !== 2)
    }
    return <Options options={options} />
  }

  switch (Number(contactType)) {
    case INUI_CONTACT_TYPE.BICYCLE_PARKING:
      return (
        <>
          <InputContainerStyle>
            <InputSelect
              type='number'
              name='bicycle_count'
              placeholder="台数*"
              min={0}
              required
              ref={register({ valueAsNumber: true, required: true })}
            />
            {errors.bicycle_count && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <DatePicker name='start_day' placeholder='利用開始月*' />
          <SelectContainerStyle>
            <SelectStyle name='payment_method' ref={register({ valueAsNumber: true, required: true })} required>
              <option disabled value=''>支払方法*</option>
              { _renderPaymentOptions() }
            </SelectStyle>
            {errors.payment_method && <ErrorMessageStyle>項目を選択してください。</ErrorMessageStyle>}
          </SelectContainerStyle>
        </>
      )
    case INUI_CONTACT_TYPE.PARKING:
      return (
        <>
          <SelectContainerStyle>
            <SelectStyle name='parking_type' ref={register({ valueAsNumber: true, required: true })} required>
              <option disabled value=''>駐車場タイプ*</option>
              { _renderParkingOptions() }
            </SelectStyle>
            {errors.parking_type && <ErrorMessageStyle>項目を選択してください。</ErrorMessageStyle>}
          </SelectContainerStyle>
          <InputContainerStyle>
            <InputSelect
              type='number'
              name='car_width'
              min={0}
              ref={register({ valueAsNumber: true, required: true })}
              placeholder='車両全幅*'
              required
            />
            {errors.car_width && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <InputContainerStyle>
            <InputSelect
              type='number'
              name='car_length'
              min={0}
              ref={register({ valueAsNumber: true, required: true })}
              placeholder='車両全長*'
              required
            />
            {errors.car_length && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <InputContainerStyle>
            <InputSelect
              type='number'
              name='car_height'
              min={0}
              ref={register({ valueAsNumber: true, required: true })}
              placeholder='車両高さ*'
              required
            />
            {errors.car_height && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <InputContainerStyle>
            <InputSelect
              type='number'
              name='car_weight'
              min={0}
              ref={register({ valueAsNumber: true, required: true })}
              placeholder='車両重量*'
              required
            />
            {errors.car_weight && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <DatePicker name='start_day' placeholder='契約開始希望日*' />
        </>
      )
    case INUI_CONTACT_TYPE.BIKE_PARKING:
      return (
        <>
          <SelectContainerStyle>
            <SelectStyle name='bike_type' ref={register({ valueAsNumber: true, required: true })} required>
              <option disabled value=''>バイクのタイプ*</option>
              <Options options={selectBikeTypeOptions} />
            </SelectStyle>
            {errors.bike_type && <ErrorMessageStyle>項目を選択してください。</ErrorMessageStyle>}
          </SelectContainerStyle>
          <DatePicker name='start_day' placeholder='契約開始希望日*' />
        </>
      )
    case INUI_CONTACT_TYPE.TRUNK_ROOM:
      return (
        <>
          <SelectContainerStyle>
            <SelectStyle name='trunk_type' ref={register({ valueAsNumber: true, required: true })} required>
              <option disabled value=''>トランクルームのタイプ*</option>
              <Options options={selectTrunkTypeOptions} />
            </SelectStyle>
            {errors.trunk_type && <ErrorMessageStyle>項目を選択してください。</ErrorMessageStyle>}
          </SelectContainerStyle>
          <DatePicker name='start_day' placeholder='契約開始希望日*' />
        </>
      )
    case INUI_CONTACT_TYPE.PREMIUM_BICYCLE_PARKING:
      return (
        <>
          <DatePicker name='start_day' placeholder='契約開始希望日*' />
        </>
      )
    default:
      return null
  }
}

Form.propTypes = {
  control: PropTypes.func,
  property: PropTypes.number.isRequired
}

export default Form
