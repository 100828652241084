import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import { getColor, FONT_SIZE, SPACING, BORDER_RADIUS } from '../../../constants'
import { Text } from '../Typography'
import Spinner from '../../Feedback/Spinner/Spinner'

const BaseStyle = css`
    cursor: pointer;
    ${FONT_SIZE.REGULAR}
    padding: ${SPACING.SMALLEST / 2}rem ${props => props.icon ? SPACING.MEDIUM_LARGE : SPACING.LARGE}rem;
    margin: ${props => props.withMargin ? `${SPACING.SMALLEST}rem` : 0};
    ${BORDER_RADIUS}
    border: 1px solid;
    outline: none;
    transition: all 200ms ease-in-out;
    white-space: nowrap;
    position: relative;
    user-select: none;
    span{
        margin-right: ${SPACING.SMALLEST}rem;
        width: 14px;
        height: 14px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        img, svg {
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &:disabled, &:disabled:hover {
        color: ${props => getColor(props, 'text')};
        border-color: ${props => getColor(props, 'text')};
        background-color: ${props => getColor(props, 'cardBorder')};
        cursor: not-allowed;
    }
`

const PrimaryStyle = css`
    color: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'accent')};
    border-color: ${props => getColor(props, 'accent')};
    background: ${props => props.reverse ? getColor(props, 'accent') : getColor(props, 'white')};
    :hover{
        color: ${props => props.reverse ? getColor(props, 'accent') : getColor(props, 'white')};
        background: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'accent')};
    }
`
const DangerStyle = css`
    color: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'danger')};
    border-color: ${props => getColor(props, 'danger')};
    background: ${props => props.reverse ? getColor(props, 'danger') : getColor(props, 'white')};
    :hover{
        color: ${props => props.reverse ? getColor(props, 'danger') : getColor(props, 'white')};
        background: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'danger')};
    }
`

const ButtonStyle = styled.button`
    ${BaseStyle};
`
const LinkStyle = styled.a`
    ${BaseStyle};
`

export const PrimaryButtonStyle = styled(ButtonStyle)`
    ${PrimaryStyle};
`
export const InfoButtonStyle = styled(ButtonStyle)`
    color: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'info')};
    border-color: ${props => getColor(props, 'info')};
    background: ${props => props.reverse ? getColor(props, 'info') : getColor(props, 'white')};
    :hover{
        color: ${props => props.reverse ? getColor(props, 'info') : getColor(props, 'white')};
        background: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'info')};
    }
`
export const SuccessButtonStyle = styled(ButtonStyle)`
    color: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'success')};
    border-color: ${props => getColor(props, 'success')};
    background: ${props => props.reverse ? getColor(props, 'success') : getColor(props, 'white')};
    :hover{
        color: ${props => props.reverse ? getColor(props, 'success') : getColor(props, 'white')};
        background: ${props => props.reverse ? getColor(props, 'white') : getColor(props, 'success')};
    }
`
export const CustomButtonStyle = styled(ButtonStyle)`
    color: ${props => props.reverse ? getColor(props, 'white') : props.color};
    border-color: ${props => props.color};
    background: ${props => props.reverse ? props.color : getColor(props, 'white')};
    :hover{
        color: ${props => props.reverse ? props.color : getColor(props, 'white')};
        background: ${props => props.reverse ? getColor(props, 'white') : props.color};
    }
`

export const PrimaryButtonLinkStyle = styled(LinkStyle)`
    ${PrimaryStyle};
`
export const DangerButtonStyle = styled(ButtonStyle)`
    ${DangerStyle};
`
export const DeleteButtonStyle = styled.span`
    ${BaseStyle};
    ${DangerStyle};
    text-align: center;
`

export const RemoveContainerStyle = styled.div`
  cursor: pointer;
  color: ${props => getColor(props, 'danger')};
  display: inline-flex;
  align-items: center;
  align-self: center;
  margin-left: ${SPACING.SMALL}rem;
  margin-top: ${SPACING.SMALL}rem;

  &:hover {
    color: ${props => lighten(0.05, getColor(props, 'danger'))};
  }
`
export const RemoveTextStyle = styled(Text)`
  font-weight: bold;
  color: inherit;
  margin-left: ${SPACING.SMALLEST}rem;
`

export const LoadingButtonStyle = styled(ButtonStyle)`
    color: ${props => getColor(props, 'lightText')};
    border-color: ${props => getColor(props, 'lightText')};
    background-color: ${props => getColor(props, 'cardBorder')};
    cursor: not-allowed;
    user-select: none;
`
export const SpinnerStyle = styled(Spinner)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`
