
import styled from 'styled-components'
import { BORDER_RADIUS, SPACING_LEVEL, SPACING } from '../../../constants'

const getBorderColor = (props) => {
  if (props.theme && props.theme.colors && props.theme.colors.cardBorder) return props.theme.colors.cardBorder
  return 'transparent'
}

export const CardStyle = styled.section`
  ${BORDER_RADIUS}
  height: ${props => props.h ? `${props.h}px` : 'auto'};
  width: ${props => props.w ? `${props.w}px` : '100%'};
  border: 1px solid;
  border-color: ${props => getBorderColor(props)};
  margin-top: ${props => props.my ? SPACING_LEVEL(props.my) : SPACING_LEVEL(props.mt)}rem;
  margin-bottom: ${props => props.my ? SPACING_LEVEL(props.my) : SPACING_LEVEL(props.mb)}rem;
  margin-right: ${props => props.mx ? SPACING_LEVEL(props.mx) : SPACING_LEVEL(props.mr)}rem;
  margin-left: ${props => props.mx ? SPACING_LEVEL(props.mx) : SPACING_LEVEL(props.ml)}rem;
  & > *:first-child {
    ${BORDER_RADIUS};
  }
`

export const CardInnerStyle = styled.div`
  padding: ${SPACING.SMALL}rem;
  width: 100%;
  height: 100%;
`

export const CardTitleStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.SMALL}rem;
  border-bottom: 1px solid ${props => props.theme.colors.cardBorder};
`
