import i18next from 'i18next'

import commonSettings from '../../locales/commonSettings'
const { resources, ns } = commonSettings

const i18nInstance = i18next.createInstance()
i18nInstance
  .init({
    debug: process.env.REACT_APP_DEBUG_MODE === 'true',
    lng: 'ja',
    fallbackLng: 'ja', // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false,
      wait: true
    },
    resources,
    // have a common namespace used around the full app
    ns,
    defaultNS: 'validation'
  })

export default i18nInstance
