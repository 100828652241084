
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { CenteredStyle } from './Centered.style.js'
import { circular } from '../../../utils'

const Centered = ({ children, fullScreen, backgroundColor, ...props }) => {
  return (
    <CenteredStyle
      fullScreen={fullScreen}
      backgroundColor={backgroundColor}
      { ...props }
    >
      { children }
    </CenteredStyle>
  )
}

export default memo(Centered, (prevProps, nextProps) => {
  const prev = JSON.stringify(prevProps, circular())
  const next = JSON.stringify(nextProps, circular())
  return prev === next
})

Centered.propTypes = {
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  backgroundColor: PropTypes.string
}
Centered.defaultProps = {
  fullScreen: false,
  backgroundColor: null
}
