import validator from 'validator'

export const validate = {
  length: (el, length) => el.length === length,
  maxLength: (el, maxLength) => el.length <= maxLength,
  minLength: (el, minLength) => el.length >= minLength,
  regex: (el, exp) => RegExp(exp).test(el),
  katakana: (el) => RegExp(/[\u30a0-\u30ff]*/).test(el), // FIXME: update the regex to match only katakana
  date: (el) => RegExp(/\d{4}-\d{2}-\d{2}/).test(el) || el === 'null',
  dateTime: (el) => RegExp(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/).test(el) || el === 'null',
  phone: (el) => RegExp(/^\d{1,4}-\d{1,4}-\d{3,4}$/).test(el),
  url: (el) => validator.isURL(el),
  email: (el) => validator.isEmail(el),
  oneOf: (el, arr) => arr.includes(el),
  // type: (el, type) => typeof el === type,
  messages: {
    length: (label, length) => `${label}は${length}文字で入力してください。`,
    maxLength: (label, maxLength) => `${label}は${maxLength}文字以内で入力してください。`,
    minLength: (label, minLength) => `${label}は${minLength}文字以上で入力してください。`,
    regex: (label) => `${label}の形式は正しくありません。`,
    katakana: (label) => `${label}はカタカナ以外の文字が入っています。`,
    date: (label) => `${label}の形式は正しくありません。`,
    dateTime: (label) => `${label}の形式は正しくありません。`,
    phone: (label) => `${label}の形式は正しくありません。`,
    url: (label) => `${label}は正しいURLではありません。`,
    email: (label) => `${label}は正しいメールアドレスではありません。`,
    oneOf: (label, arr) => `${label}は${arr}の中から選んでください。`
    // type: (label, type) => `${label}のデータタイプは異なっています。（${type}を入力ください）`
  }
}
