import React from 'react'
import PropTypes from 'prop-types'

import {
  TitleStyle,
  SectionTitleStyle,
  CardTitleStyle,
  ToastTitleStyle,
  CommentTitleStyle,
  RegularTextStyle,
  LightRegularTextStyle,
  SmallTextStyle,
  CustomTextStyle
} from './Typography.style'
import { useTranslation } from 'react-i18next'

const Typography = ({ textType, label, text, textColor, ...props }) => {
  const { t } = useTranslation()
  if (!label && !text) return null
  switch (textType) {
    case 'title':
      return <TitleStyle {...props}>{text || t(label)}</TitleStyle>
    case 'sectionTitle':
      return <SectionTitleStyle {...props}>{text || t(label)}</SectionTitleStyle>
    case 'cardTitle':
      return <CardTitleStyle {...props}>{text || t(label)}</CardTitleStyle>
    case 'toastTitle':
      return <ToastTitleStyle {...props}>{text || t(label)}</ToastTitleStyle>
    case 'commentTitle':
      return <CommentTitleStyle {...props}>{text || t(label)}</CommentTitleStyle>
    case 'regularText':
      return <RegularTextStyle {...props}>{text || t(label)}</RegularTextStyle>
    case 'lightText':
      return <LightRegularTextStyle {...props}>{text || t(label)}</LightRegularTextStyle>
    case 'smallText':
      return <SmallTextStyle {...props}>{text || t(label)}</SmallTextStyle>
    case 'customText':
      return <CustomTextStyle textColor={textColor} {...props}>{text || t(label)}</CustomTextStyle>
    default:
      return null
  }
}

export const Title = (props) => {
  return <Typography textType="title" {...props} />
}
export const SectionTitle = (props) => {
  return <Typography textType="sectionTitle" {...props} />
}
export const CardTitle = (props) => {
  return <Typography textType="cardTitle" {...props} />
}
export const ToastTitle = (props) => {
  return <Typography textType="toastTitle" {...props} />
}
export const CommentTitle = (props) => {
  return <Typography textType="commentTitle" {...props} />
}
export const Text = (props) => {
  return <Typography textType="regularText" {...props} />
}
export const LightText = (props) => {
  return <Typography textType="lightText" {...props} />
}
export const SmallText = (props) => {
  return <Typography textType="smallText" {...props} />
}
export const CustomText = ({ textColor, textSize, ...rest }) => {
  return <Typography textType="customText" textColor={textColor} textSize={textSize} {...rest} />
}

CustomText.propTypes = {
  textColor: PropTypes.string.isRequired,
  textSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

Typography.propTypes = {
  textType: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  textColor: PropTypes.string,
  textSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}
Typography.defaultProps = {

}
