import ReactGA from 'react-ga'
import ttiPolyfill from 'tti-polyfill'
import { GOOGLE_TRACKING_ID } from '../constants'

export const initGA = () => {
  ReactGA.initialize(GOOGLE_TRACKING_ID, {
    standardImplementation: true,
    debug: process.env.REACT_APP_STAGE !== 'production' && process.env.REACT_APP_DEBUG_MODE === 'true',
    gaOptions: { siteSpeedSampleRate: 100 }
  })
}

const handleObserver = list => {
  list.getEntries().forEach(entry => {
    // if (entry.entryType === 'measure') {
    //   console.log('%c mark entry', 'background: #222; color: #00ff23', entry)
    // }
    if (entry.name.includes('App') && entry.duration) {
      // console.log('%c mark entry', 'background: #222; color: #bada55', entry)
      ReactGA.timing({
        category: 'App Render Performance',
        variable: entry.name,
        value: Number(entry.duration)
      })
    }
    if (entry.name.includes('Page') && entry.duration) {
      // console.log('%c mark entry', 'background: #222; color: #bada55', entry)
      ReactGA.timing({
        category: 'Page Render Performance',
        variable: entry.name,
        value: Number(entry.duration)
      })
    }
    if (entry.entryType === 'navigation') {
      // console.log('%c nav entry', 'background: #222; color: #129777', entry)
      ReactGA.timing({
        category: 'Load Performance',
        variable: 'Server Latency',
        value: entry.responseStart - entry.requestStart
      })
      ReactGA.timing({
        category: 'Load Performance',
        variable: 'Download time',
        value: entry.responseEnd - entry.responseStart
      })
      ReactGA.timing({
        category: 'Load Performance',
        variable: 'Total app load time',
        value: entry.responseEnd - entry.requestStart
      })
    }
    // if (entry.entryType === 'paint') {
    //   ReactGA.timing({
    //     category: 'Paint',
    //     variable: entry.name,
    //     value: entry.startTime
    //   })
    // }
    // if (entry.entryType === 'resource') {
    //   ReactGA.timing({
    //     category: 'First Meaningful Paint',
    //     variable: entry.name,
    //     value: entry.responseEnd
    //   })
    // }
  })
}

const sendTTI = () => {
  ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
    ReactGA.timing({
      category: 'Load Performance',
      variable: 'Time to Interactive',
      value: tti
    })
  })
}

const sendPageView = ({ location }) => {
  // console.log('location', location)

  // TODO: group page by functions not by ids
  // Custom pageview example (user/3543564/account => user/account)
  // if (location.pathname.includes('/user')) {
  //   const rootURL = location.pathname.split('/')[1]
  //   const userPage = location.pathname.split('/')[3]

  //   const pageHit = `/${rootURL}/${userPage}`
  //   ReactGA.pageview(pageHit)
  // }

  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
}

export const GAListener = (history) => {
  // TODO: set this to work only in production and reset data on first release
  // if (process.env.REACT_APP_STAGE === 'production') {

  if (history.location) {
    sendPageView(history)
  }

  history.listen(sendPageView)

  sendTTI()

  var observer = new PerformanceObserver(handleObserver)
  observer.observe({
    entryTypes: [
      'navigation',
      'mark',
      'measure'
    ]
  })
  // }
}

// Events examples
// ReactGA.event({
//   category: 'User',
//   action: 'Created an Account'
// });

// ReactGA.event({
//   category: 'Social',
//   action: 'Rated an App',
//   value: 3
// });

// ReactGA.event({
//   category: 'Editing',
//   action: 'Deleted Component',
//   label: 'Game Widget'
// });

// ReactGA.event({
//   category: 'Promotion',
//   action: 'Displayed Promotional Widget',
//   label: 'Homepage Thing',
//   nonInteraction: true
// });

// args.category String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
// args.action String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
// args.label String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
// args.value Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
// args.nonInteraction Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
// args.transport String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
