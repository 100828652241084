
import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '../../Feedback/Modal'
import {
  downloadImage
} from '../../../utils'

import {
  DownloadOutlined
} from '@ant-design/icons'

import noImgPlaceholder from '../../../assets/svg/no_img.svg'

import { ImageStyle } from './Image.style'

const Image = ({ src, alt, w, h, full, cover, contain, previewable, className }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleCancel = useCallback(
    () => {
      setIsVisible(false)
    },
    []
  )
  const handleDownload = useCallback(
    () => {
      downloadImage(src, 'image')
    },
    [src]
  )

  const _handleImageError = (e) => {
    e.target.onerror = null
    e.target.src = noImgPlaceholder
  }

  const _handleImageClick = () => {
    if (!previewable) return null
    setIsVisible(true)
  }

  const _renderImage = (previewable) => {
    return (
      <ImageStyle
        w={w}
        h={h}
        full={full}
        cover={cover}
        contain={contain}
        onClick={_handleImageClick}
        previewable={previewable}
        className={className}
      >
        <img
          src={src || noImgPlaceholder}
          alt={alt || ''}
          onError={_handleImageError}
        />
      </ImageStyle>
    )
  }

  return (
    <>
      <Modal
        visible={previewable && isVisible}
        okIcon={<DownloadOutlined />}
        okText='download'
        cancelText='cancel'
        onOk={handleDownload}
        onCancel={handleCancel}
      >
        { _renderImage() }
      </Modal>
      { _renderImage(previewable) }
    </>
  )
}
export default memo(Image)

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  h: PropTypes.string,
  w: PropTypes.string,
  full: PropTypes.bool,
  cover: PropTypes.bool,
  contain: PropTypes.bool,
  previewable: PropTypes.bool,
  className: PropTypes.string
}
Image.defaultProps = {
  src: null,
  alt: '',
  h: 'auto',
  w: 'auto',
  full: false,
  cover: false,
  contain: false,
  previewable: false,
  radius: '0px'
}
