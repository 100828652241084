import axios from 'axios'
const {
  REACT_APP_MAIN_API_URL,
  REACT_APP_API_KEY
} = process.env

const configureAxios = (baseURL = REACT_APP_MAIN_API_URL) => {
  const axiosInstance = axios.create({
    baseURL,
    timeout: 60 * 1000, // API request timeout set to 60s
    headers: {
      'APMC-API-KEY': REACT_APP_API_KEY
    }
  })

  // abort duplicate request
  // const pending = {}
  // const handleCancel = (config, cancel) => {
  //   const params = config.params ? `_${JSON.stringify(config.params)}` : ''
  //   const url = config.url.indexOf(config.baseURL) !== -1 ? config.url.replace(config.baseURL, '') : config.url
  //   const flagUrl = `${config.method.toUpperCase()}_${url}${params}`
  //   // console.log('flagURL', flagUrl)
  //   if (flagUrl in pending) {
  //     // console.log('flag in pending', flagUrl, pending)
  //     if (cancel) {
  //       // console.log('abort request')
  //       cancel('Operation canceled to prevent duplicate request', flagUrl) // abort the request
  //     } else {
  //       // console.log('remove from pending')
  //       delete pending[flagUrl] // remove from pending
  //     }
  //   } else {
  //     if (cancel) {
  //       // console.log('set to pending', flagUrl, pending)
  //       pending[flagUrl] = cancel // store the cancel function
  //     }
  //   }
  //   // console.log('end pending', flagUrl, pending)
  // }

  // Set header in interceptor instead of instance definition
  // Prevent duplicate request
  axiosInstance.interceptors.request.use(async config => {
    // console.log('config', config)
    // if (!config.noCancel) {
    //   // console.log('executed', config)
    //   const CancelToken = axios.CancelToken
    //   config.cancelToken = new CancelToken(function executor (c) {
    //     handleCancel(config, c)
    //   })
    // }
    return config
  }, error => {
    Promise.reject(error)
  })

  // axiosInstance.interceptors.response.use(response => {
  //   // handleCancel(response.config)
  //   return response
  // }, error => {
  //   // handleCancel(error.config)
  //   // Send error body from API if it exists
  //   error = error.response && error.response.data ? error.response.data : error
  //   // console.log('customError', error)
  //   // if (!axios.isCancel(error)) {
  //   //   return Promise.reject(error)
  //   // } else {
  //   //   // return empty object for aborted request
  //   //   return Promise.resolve({})
  //   // }
  // })

  axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const err = new Error()
    err.data = 'サーバエラー'
    if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data)
    // console.log(error.response.status)
    // console.log(error.response.headers)
      err.data = error.response.data
    }
    return Promise.reject(err)
  })

  return axiosInstance
}

export const MULTIPART_HEADERS = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export default configureAxios
