import commonJa from './ja/common.json'
import navigationJa from './ja/navigation.json'
import validationJa from './ja/validation.json'
import notFoundJa from './ja/pageNotFound.json'
import inuiJa from './ja/inui.json'
import userResetJa from './ja/userReset.json'

import commonEn from './en/common.json'
import navigationEn from './en/navigation.json'
import validationEn from './en/validation.json'
import notFoundEn from './en/pageNotFound.json'
import inuiEn from './en/inui.json'
import userResetEn from './en/userReset.json'

const commonSettings = {
  resources: {
    ja: {
      common: commonJa,
      navigation: navigationJa,
      validation: validationJa,
      not_found: notFoundJa,
      inui: inuiJa,
      user_reset: userResetJa
    },
    en: {
      common: commonEn,
      navigation: navigationEn,
      validation: validationEn,
      not_found: notFoundEn,
      inui: inuiEn,
      user_reset: userResetEn
    }
  },
  ns: [
    'common',
    'navigation',
    'validation',
    'not_found',
    'inui',
    'user_reset'
  ]
}

export default commonSettings
